import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import SnapshotService from "../../Services/Snapshot/SnapshotService";
import MasterService from '../../Services/MasterService';
import { toast } from 'react-toastify';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { changeDateFormate, displayDateFormate } from '../../Helpers/DateFormat';
import FinancialYearNew from "../../Components/BoilerPlate/FinancialYearNew";
import SnapshotFilter from "./SnapshotFilter";
import ShowWidgets from './ShowWidgets';
import TagsList from '../../Components/BoilerPlate/ItemListingOnTable';
import {
  ReadyStatus, NotReadyStatus, ProcessedStatus, LinkedStatus, SyncedStatus, AttachmentIcon,
  TDSIcon, GSTIcon, MessageIcon, TaskIcon, ArrowUpDownIcon, SortingIcon
} from '../IcoonNew';
import StorageData from "../../Helpers/StorageData";
import { setBulkSnapshotList, setSnapshotCardFilter, setSnapshotSearchText } from "../../Redux/Actions/Snapshot";
import {
  CREATE_BUSSINESS_SNAPSHOT_CODE, MANAGE_OTHER_USER_SNAPSHOT_CODE, VIEW_ONLY_SNAPSHOT_CODE, CREATE_REIMBUSRMENT_SNAPSHOT_CODE,
  APPROVE_REIMBURSMENT_SNAPSHOT_CODE, ACCESS_BUSSINESS_SMS_SNAPSHOT_CODE, SMART_SCAN_SNAPSHOT_CODE, SNAPSHOT_LEDGER_ENTRIES_CODE
} from '../../Helpers/Constants';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';
import NotProcessedStatus from '../IcoonNew/NotProcessedStatus';
import { showAmount } from '../../Helpers/Currency';
import { checkFileExtension } from '../../Helpers/CheckValidation';
import { setAppilcationLoder } from "../../Redux/Actions/App";
import MoneyLinkService from "../../Services/MoneyLink/MoneyLinkService";
import BulkUploadPopup from "../../Components/Snapshot/BulkUploadPopup";
import ImportExcelPopup from "../../Components/Snapshot/ImportExcelPopup";
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { Button, Dropdown } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ImportCostCenter from '../../Components/BoilerPlate/ImportCostCenter';
import ImportLedger from '../../Components/BoilerPlate/ImportLedger';
import ExportVouchers from '../../Components/BoilerPlate/ExportVouchers';
import ChangeLog from '../../Components/BoilerPlate/ChangeLog';
import { confirm } from '../../Components/BoilerPlate/Confirm';
import Select from 'react-select';
import excelFile from "../../Assets/img/Buktec-Snapshot-Excel-Import-Sample.xlsx";

// Intro js user guid
import { render } from "react-dom";
import { Steps } from "intro.js-react";
import { API_URL } from '../../Utils/Config';
import { sendLog } from '../../Helpers/CreateLogs';
// import BulkRouteLeavingGuard from '../../Utils/BulkRouteLeavingGuard';
//import { is } from 'date-fns/locale';
// import "intro.js/introjs.css";
// End Intro js user guid



/* static ui elements */
const td_style = { /*whiteSpace: 'normal', wordWrap: 'break-word'*/ };

class SnapshotQuickEntry extends Component {


  constructor(props) {
    super(props);

    this.PERMISSION_LIST = this.props.features_permissions_list;
    this.CREATE_BUSSINESS_SNAPSHOT = this.PERMISSION_LIST.includes(CREATE_BUSSINESS_SNAPSHOT_CODE);
    this.MANAGE_OTHER_USER_SNAPSHOT = this.PERMISSION_LIST.includes(MANAGE_OTHER_USER_SNAPSHOT_CODE);
    this.VIEW_ONLY_SNAPSHOT = this.PERMISSION_LIST.includes(VIEW_ONLY_SNAPSHOT_CODE);
    this.CREATE_REIMBUSRMENT_SNAPSHOT = this.PERMISSION_LIST.includes(CREATE_REIMBUSRMENT_SNAPSHOT_CODE);
    this.APPROVE_REIMBURSMENT_SNAPSHOT = this.PERMISSION_LIST.includes(APPROVE_REIMBURSMENT_SNAPSHOT_CODE);
    this.ACCESS_BUSSINESS_SMS_SNAPSHOT = this.PERMISSION_LIST.includes(ACCESS_BUSSINESS_SMS_SNAPSHOT_CODE);
    this.SMART_SCAN_SNAPSHOT = this.PERMISSION_LIST.includes(SMART_SCAN_SNAPSHOT_CODE);
    this.SNAPSHOT_LEDGER_ENTRIES_CODE = this.PERMISSION_LIST.includes(SNAPSHOT_LEDGER_ENTRIES_CODE);

    this.VIEW_ONLY_SNAPSHOT = this.VIEW_ONLY_SNAPSHOT &&
      !(this.CREATE_REIMBUSRMENT_SNAPSHOT || this.CREATE_BUSSINESS_SNAPSHOT || this.MANAGE_OTHER_USER_SNAPSHOT);

    this.state = {
      cell: [],
      show_tags_tooltip: false,
      tag_target: null,
      active_input: false,

      total_count: 0,
      per_page_items: 0,
      show_filter_popup: this.props.filter_by == 2,
      search: "",
      show_bulk_upload_popup: false,
      show_import_excel_popup: false,
      system_parameters: this.props.system_parameters,
      show_log: false,
      logs_list: {},
      // Intro js user guid
      // this.state = {
      stepsEnabled: false,
      initialStep: 0,
      //bulk action
      disabled_transaction : [],
      selected_items: [],
      show_select_all: false,
      is_dirty:false,
      closed:false,
      steps: [
        {
          element: ".dropdown_snapin",
          intro: "You can select your focus ‘Time period’ from the dropdown menu.",
          // position: 'right',
          // hideNext: true 
        },
        {
          element: ".widgets_snapin",
          intro: "These widgets not only give you the high level numerical work status but also act as a filter after clicking."
        },
        {
          element: ".notready_usrin",
          intro: " It's a draft mode where you upload some snapshot on the fly."
        },
        {
          element: ".ready_usrin",
          intro: "It indicates that the snapshot is ready for the accountant to pick up for processing."
        },
        {
          element: ".pending_usrin",
          intro: "You can see the Accounting status on this widget. Click on the right top corner to Toggle the status. **Pending – This shows snapshots which are pending for accounting. **Accounted – This shows snapshot for which accounting entry is done."
        },
        {
          element: ".synched_usrin",
          intro: "It indicates that the ledger entry is exported to accounting software."
        },
        {
          element: ".fapproval_usrin",
          intro: "It indicates the snapshot which are submitted to you and are pending for approval.",
          position: 'left',
        },
        {
          element: ".linked_usrin",
          intro: "It indicated that the snapshot is linked to a particular bank transaction. Use the toggle on top right to switch.",
          position: 'left',
        },
        {
          element: ".filter_snapin",
          intro: "You can filter the table based on different criteria.",
          position: 'left',
        },
        {
          element: ".table_snapin",
          intro: "The table gives you the data for the selected time period. You can click on any of them to view / edit. Last few icons in the row gives additional information like Attachment, TDS, GST, Messages and notes.",
        }
        // {
        //   element: ".total_usrin",
        //   intro: "sdsd"
        // },
        // {
        //   element: ".processed_usrin",
        //   intro: "It indicates that the accountant has made the ledger entry for the document."
        // },
        // {
        //   element: ".import_snapin",
        //   intro: "Sync your accounting software ledgers by importing them to Buktec. Contact us for software specific imports.",
        // },
        // {
        //   element: ".export_snapin",
        //   intro: "Sync your accounting software by exporting ledger entries from Buktec. Contact us for software specific exports.",
        // },
      ],
      // };
      // End Intro js user guid
      

    };
    this.handleSizePerPageChange = this.handleSizePerPageChange.bind(this);
    this.renderShowsTotal = this.renderShowsTotal.bind(this);

  }


  setInitialRowsValue = () => {
    //set preselected rows
    let selected_items = this.props.bulk_snapshot_list.map(trans => trans.id);
		this.setState({ selected_items })

  }
  customMultiSelect(props) {
    const { type, checked, disabled, onChange, rowIndex } = props;
    /*
    * If rowIndex is 'Header', means this rendering is for header selection column.
    */
    if (rowIndex === 'Header') {
        return (
            <div className='checkbox-personalized'>
                <Checkbox {...props} />
                <label htmlFor={'checkbox' + rowIndex}>
                    <div className='check'></div>
                </label>
            </div>);
    } else {
        return (
            <div className='checkbox-personalized'>
                <input
                    type={type}
                    name={'checkbox' + rowIndex}
                    id={'checkbox' + rowIndex}
                    checked={checked}
                    disabled={disabled}
                    onChange={e => onChange(e, rowIndex)}
                    ref={input => {
                        if (input) {
                            input.indeterminate = props.indeterminate;
                        }
                    }} />
                <label htmlFor={'checkbox' + rowIndex}>
                    <div className={'check ' + (disabled ? ' disable-bg ' : '')}></div>
                </label>
            </div>);
    }
}

  onRowSelect = (rows, isSelected) =>{
    let selected_items = this.state.selected_items;
    let bulk_snapshot_list = this.props.bulk_snapshot_list;
    if(isSelected){
            selected_items.push(rows.id);
			bulk_snapshot_list.push(rows);
    }
    else {
        selected_items = selected_items.filter(item => item != rows.id);
        bulk_snapshot_list = bulk_snapshot_list.filter(item => item.id != rows.id);
    }
    this.props.dispatch(setBulkSnapshotList(bulk_snapshot_list));
    this.setState({
        selected_items: selected_items, show_select_all : true,is_dirty:true
    });

    return selected_items;


  }

  onRowSelectAll = (is_selected,rows) =>{
    if(is_selected){
        let bulk_snapshot_list = [...rows, ..._.cloneDeep(this.props.bulk_snapshot_list)];
			let selected_items = bulk_snapshot_list.map(data => data.id);
			this.setState({ selected_items: [...this.state.selected_items, ...selected_items], show_select_all: true ,is_dirty:true});
			this.props.dispatch(setBulkSnapshotList(bulk_snapshot_list));
    }
    else{
        let selected_items = this.props.snapshot_list.map(data => data.id);
			let bulk_snapshot_list = this.props.bulk_snapshot_list.filter(transaction => { return !selected_items.includes(transaction.id); });
			let items = this.state.selected_items.filter(id => { return !selected_items.includes(id); });
			this.setState({ selected_items: items });
			this.props.dispatch(setBulkSnapshotList(bulk_snapshot_list))
    }

  }

  selectedSnapshots = (list) => {
		console.log(list)
		this.setState({ selected_items: list })
	}

  resetSelection = () => {
		this.setState({ selected_items: [], show_select_all: false })
		this.props.dispatch(setBulkSnapshotList([]));
	}

  toggleFilterPopup = () => {
    this.setState({ show_filter_popup: !this.state.show_filter_popup })
  }

  searchingText = (e) => {
    this.props.dispatch(setSnapshotSearchText(e.target.value));
    if (!e.target.value) {
      this.props.changeIsSearchApplied(false)
      this.props.searchFilter()
    }
  }
  componentDidUpdate(prevProps) {	
    if (prevProps.page_no != this.props.page_no) {
        this.setInitialRowsValue();
    }
}
componentWillUnmount= async()=>{
//   this.props.dispatch(setBulkSnapshotList([]))
}
  componentDidMount = async () => {
    // StorageData.deleteTransactionId()
    // StorageData.deleteTaskManagerId()


    // if (!!this.props.location.state) {
    //   if (!!this.props.location.state.showalert) {
    //     await confirm({
    //       confirmation: "Unable to show specified ID. Please check active entity and ID.",
    //       options: { type: "alert" }
    //     })
    //     window.history.replaceState({}, document.title)
    //   }
    // }
    // this.showFilter();
		this.setInitialRowsValue();
		this.props.onRef(this);
  }



  renderShowsTotal(start, to, total) {
    return (
      <p className="main-pagi-show">
        <span className="pagi-show">{start}</span> to <span className="pagi-show">{to}</span> of <span className="pagi-show">{total}</span>
      </p>
    );
  }
  _setNoDataFoundOption() {
    if (!this.props.is_snapshot_loading) {
      return "No items to display";
    } else {
      return (
        <p style={{ position: 'relative', margin: '0px auto' }}>Loading ...</p>
      );
    }
  }

  handleSizePerPageChange(sizePerPage) {
    // When changing the size per page always navigating to the first page
    this.setState({
      per_page_items: sizePerPage
    }, () => this.getInvoiceList(this.state.filter_by))
  }

  dataFormatStatus = (cell, row) => {
    switch (cell) {
      case 1:
        return <NotReadyStatus />
      case 2:
        return <ReadyStatus />
      case 4:
        return <ProcessedStatus />
      case 5:
        return <SyncedStatus />
    }
  }

  displayAmount = (cell, row) => {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className=""><span className={row.expense == 0 ? "green-color" : "red-color"}>{showAmount(cell)} {row.is_duplicate ? "(Duplicate)" : ""}</span></Tooltip>}>
      <span className={row.expense == 0 ? "green-color" : "red-color"}>{!!cell ? showAmount(cell) : ""} {!!cell ? <ArrowUpDownIcon expense={row.expense} /> : ""} </span>
    </OverlayTrigger>
  }

  // checkMessagesStatus  

  dataFormatTask = (cell, row) => {

    return <div>
      <AttachmentIcon active={row.attachment_status} />
      <TDSIcon active={row.tds_status} />
      <GSTIcon active={row.gst_status} />
      {/* <MessageIcon active={row.general_msg_status || row.private_msg_status}/> */}
      <LinkedStatus active={row.is_linked}></LinkedStatus>
      <TaskIcon active={row.tasks_status || row.general_msg_status || row.private_msg_status || row.notes_status} />
    </div>
  }

  dataFormatPaymentInfo = (cell, row) => {
    let payment_method = cell.map((payment, index) => {
      return payment.name;
    })
    return payment_method.toString();
  }

  checkForEnterButton = (e) => {
    if (e.charCode === 13 && this.props.search_text) {
      this.searchFilter(e)
    } else {
      this.props.dispatch(setSnapshotSearchText(e.target.value))
    }
  }

  goToEditView = (row) => {
    // const { dispatch, history } = this.props;
    // history.push({
    //   pathname: `/snapshot/evidence/create/` + row.id,
    //   state: {
    //     search: this.props.search_text
    //   }
    // });
  }

  onSortClick = (sort_order, sort_by) => {
    this.props.onSortClick(sort_order, sort_by)
  }

  searchFilter = (e) => {
    e.preventDefault();
    if (!this.props.search_text) {
      toast.error("Enter search text")
      return
    }
    this.props.searchFilter(this.props.search_text);
    this.props.changeIsSearchApplied(true)
    this.setState({ show_filter_popup: false })
    if (this.props.filter_by == 2) {
      toast.warning("Removed filter data and applied search filter")
    }

  }

  clearSearch = (e) => {
    e.preventDefault();
    this.props.searchFilter("");
    this.props.dispatch(setSnapshotSearchText(""));
    this.setState({ active_input: false })
    this.props.changeIsSearchApplied(false)
  }

  resetSearch = () => {
    // this.props.searchFilter("");
    this.props.dispatch(setSnapshotSearchText(""));
    this.setState({ active_input: false })
    this.props.changeIsSearchApplied(false)
  }

  changePaymentInfoStyle = (cell, row) => {
    if (!!cell.length)
      return (cell[0].name == "reimbursement" ? { color: '#5E9FFD' } : {})
    else
      return {}
  }

  makeRemoveStar = (e, row) => {
    e.stopPropagation();
    let data = {
      id: row.id,
      is_star: row.is_star == 0 ? 1 : 0
    }
    this.props.makeRemoveStar(row.id, data.is_star)
    SnapshotService.makeRemoveStar(data).then(
      (data) => {
        if (data.response_code == 200) {
        } else {
          this.props.makeRemoveStar(row.id, row.is_star)
          toast.error(data.message);
        }
      });
  }

  displayDate = (cell, row) => {
    //console.log(row)
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{"ID #" + row.id}  {'Created On: ' + displayDateFormate(row.created_at)} {row.is_duplicate ? "(Duplicate)" : ""}</Tooltip>}>
      <span>{displayDateFormate(cell)}</span>
    </OverlayTrigger>
  }
  dataFormatStar = (cell, row) => {
    switch (cell) {
      case 1:
        return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td active"></span></span>

      case 0:
        return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td"></span></span>
    }
  }
  dataFormatType = (cell, row) => {

    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{cell} {row.is_duplicate ? "(Duplicate)" : ""}</Tooltip>}>
      <span>{cell}</span>
    </OverlayTrigger>
  }

  displayTags = (cell, row) => {
    return <TagsList itemList={cell} />
  }

  showTags = (cell, row) => {
    if (!!row.tags) {
      return row.tags.toString();
    }
  }

  rowStyleFormat = (row, rowIdx) => {
    if (!!row)
      if (row.is_duplicate == 1) {
        return { backgroundColor: '#cccccc4a' };
      }
  }

  displayCname = (cell, row) => {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{cell} {row.is_duplicate ? "(Duplicate)" : ""}</Tooltip>}>
      <span>{cell}</span>
    </OverlayTrigger>
  }

  // Intro js user guid   
  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
  };
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  // End Intro js user guid   

  displayCategory = (cell, row) => {
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{cell} {row.is_duplicate ? "(Duplicate)" : ""}</Tooltip>}>
      <span>{cell}</span>
    </OverlayTrigger>
  }

  displayPendingAmount = (cell, row) => {

    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{showAmount(row.pending_amount)} {row.is_duplicate ? "(Duplicate)" : ""}</Tooltip>}>
      {
        (row.entry_status == 1 && row.is_linked == 0) ? <span className='text-dark'>{showAmount(row.pending_amount)}</span> : <span className='text-light-blue'>{showAmount(row.pending_amount)}</span>
      }
    </OverlayTrigger>
  }

  toggleBulkUploadPopup = async() => {
    if (!!this.props.checkBulkFlag) {
			if (await this.props.checkBulkFlag()) {
				return true;
			}
		}
    this.setState({ show_bulk_upload_popup: !this.state.show_bulk_upload_popup })
  }

  toggleImportExcelPopup = async() => {
    if (!!this.props.checkBulkFlag) {
      if (await this.props.checkBulkFlag()) {
        return true;
      }
    }
    this.setState({ show_import_excel_popup: !this.state.show_import_excel_popup })

    // this.setState({ show_import_excel_popup: true })

    // this.state.show_import_excel_popup == false ? (
    // 	this.setState({ show_import_excel_popup: true })
    // ) : (
    // 	this.setState({ show_import_excel_popup: false })
    // )
  }

  finishBulkUpload = (new_snapshots) => {
    this.setState({ show_bulk_upload_popup: !this.state.show_bulk_upload_popup })
    this.props.finishBulkUpload(new_snapshots)
    //window.location.reload(false);
  }

  getLogs = () => {
    this.props.dispatch(setAppilcationLoder(true));
    let detail = { mod_id: null, module: 1 }
    MasterService.getLogs(detail).then((data) => {
      this.props.dispatch(setAppilcationLoder(false));
      if (data.response_code === 200) {
        this.setState({ logs_list: data.result.change_logs })
      } else {
        toast.error(data.message)
      }
      this.setState({ show_log: true })
    })
  }

  toggleChangeLog = () => {
    this.setState({ show_log: !this.state.show_log })
  }

  downloadSampleExcelFile() {
    // Create a temporary link element
    const link = document.createElement("a");
    link.href = excelFile;
    link.download = "Buktec-Snapshot-Excel-Import-Sample.xlsx"; // Set the file name for download
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
  }
  closeAction = () => {
		this.setState({ closed: false })
	}
  saveBeforeLeave = (nextRoute) => {
		this.setState({ save_and_redirect: true, next_route: nextRoute }, function () {
		});
	}

  render() {

    console.log(":::::",this.props.bulk_snapshot_list)
    console.log(":::::",this.state.selected_items)
    let is_dirty = this.state.is_dirty
    if(this.state.selected_items.length == 0){
      is_dirty = false
    }else{
      is_dirty = true
    }
    
    
    //console.log(this.props.isSearchApplied)
    // Intro js user guid 
    const {
      stepsEnabled,
      steps,
      initialStep,
      hintsEnabled,
      hints
    } = this.state;
    // End Intro js user guid 


    const selectRowProp = {
        // columnWidth: '100px',
        columnWidth: '50px',
        onSelect: this.onRowSelect,
        onSelectAll: this.onRowSelectAll,
        mode: 'checkbox',
        selected: this.state.selected_items,
        clickToSelect: true,
        // onSelect: this.handleRowSelect
        customComponent: this.customMultiSelect
    };


    const options = {
      onPageChange: this.props.handlePageChange,
      onSizePerPageList: this.handleSizePerPageChange,
      page: this.props.page_no,
      sizePerPage: this.props.per_page_items,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: 'Prev',
      nextPage: 'Next',
      firstPage: 'First',
      lastPage: 'Last',
      //paginationShowsTotal: this.renderShowsTotal,
      hideSizePerPage: true,
      onRowClick: (row) => {
        this.goToEditView(row);
      },
      noDataText: this._setNoDataFoundOption(),
    };

    const myRef = React.createRef()

    return (
      <main className="content-wrapper-new">
        <div className="container-fluid container-padding-new">
          {/* Intro js user guid  */}
          <Steps
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={this.onExit}
          />
          {/* <BulkRouteLeavingGuard
						when={is_dirty}
						saveChanges={this.saveBeforeLeave}
						closed={this.state.closed}
						closeAction={this.closeAction}
						// resetForm={this.resetForm}
					/> */}

          {/* Breadcrumb */}
          <div className="row">
            <div className="col-12">
              <div className="main-sub-header-new">
                <div className="breadcrumb-header-new">
                  <div className="row">
                    <div className="col-12">
                      <BreadCrum level={2} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <h2 className="breadcrumb-title-new">
                        Snapshot - Quick Entry
                        <span className="help-icon" onClick={this.toggleSteps}>
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Help</Tooltip>}><span className="icon-Information help-icon"></span></OverlayTrigger>
                        </span>
                      </h2>
                    </div>
                    <div className="col-4">
                      <h2 className="breadcrumb-title-new sub-header-cicon">
                        <span className="icon-round-info-button icon-log cpointer" onClick={this.getLogs}></span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrumb */}

          {/* Filter */}
          <div className="row mainFilter-new sec-mb">
            <div className="col-12">
              <div className="top-new-filter">
                {/* <div className="filter-bar-new"> */}
                <FinancialYearNew
                  financialYearChanged={this.props.financialYearChanged}
                  refreshItem={this.props.refreshItem}
                  hideRefreshItem={true}
                  dateTypeChanged={this.props.dateTypeChanged}
                  date_type={this.props.date_type}
                  checkBulkFlag ={this.props.checkBulkFlag}
                />
                {/* </div> */}

                <div className="filter-search-field">
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Company, Invoice No.</Tooltip>}>
                    <form className={"search-new-sec" + (!this.props.isSearchApplied ? "" : " active-filter")}>
                      <input type="text"
                        className={"form-control filter-btn-new " + (this.state.active_input ? " activeNew-search" : "")}
                        placeholder="Search"
                        onKeyPress={this.checkForEnterButton}
                        onFocus={() => this.setState({ active_input: true })}
                        //onBlur={() => this.setState({ active_input: false })}
                        value={this.props.search_text}
                        onChange={this.searchingText}
                        name="search" />
                      {!this.props.isSearchApplied ?
                        <Button onClick={this.searchFilter} className={this.state.active_input ? " active-new-search-icon " : ""}>
                          <span className="icon-loupe"></span>
                        </Button>
                        :
                        <Button onClick={this.clearSearch} className={this.state.active_input ? " active-new-search-icon crosebtn " : " crosebtn "}>
                          <span class="icon-cancel"></span>
                        </Button>
                      }
                    </form>
                  </OverlayTrigger>

                  <Button variant={(this.props.filter_by == 2) ? " outline-secondary filter-btn-new border-radius-26 active-filter filter_snapin " : " outline-secondary filter-btn-new border-radius-26 filter_snapin"}
                    onClick={() => this.toggleFilterPopup()}
                  >Filter <span className="icon-funnel gray-dark-icon filter-icon"></span></Button>

                  {(this.CREATE_BUSSINESS_SNAPSHOT || this.CREATE_REIMBUSRMENT_SNAPSHOT) &&
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add New</Tooltip>}>
                      <Button variant="success add-new-btn add-btn-height"
                        onClick={async() => {
                          if (!!this.props.checkBulkFlag) {
                            if (await this.props.checkBulkFlag()) {
                              return true;
                            }
                          }
                          this.props.history.push('/snapshot/evidence/create')}}><span className="icon-plus1"></span></Button>
                    </OverlayTrigger>
                  }

                  {/* Actions Menu */}
                  <Dropdown className='action-new-menu'>
                    <Dropdown.Toggle variant="" id="dropdown-basic" className='success add-new-btn action-btn-height'>
                      <span class="icon-menu-lines white-icon"></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <button onClick={async() => {
                          if (!!this.props.checkBulkFlag) {
                            if (await this.props.checkBulkFlag()) {
                              return true;
                            }
                          }
                          this.props.history.push('/snapshot/evidence')}} >
                          <span class="action-new-icon-sec">
                            {/* <span class="icon-Group-4403 action-new-icon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span> */}
                            <span class="icon-invoice1 action-new-icon"></span>
                          </span>
                          <span>Snapshot List</span></button>                   
                          <hr />

                      {(this.CREATE_BUSSINESS_SNAPSHOT) && <><button onClick={this.toggleBulkUploadPopup} >
                        <span class="action-new-icon-sec">
                          <span class="icon-Group-4403 action-new-icon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                        </span>
                        <span>Bulk Upload</span></button>
                        <hr /></>
                      }

                      <button onClick={async() => {
                            if (!!this.props.checkBulkFlag) {
                              if (await this.props.checkBulkFlag()) {
                                return true;
                              }
                            }
                        this.props.getSnapshotList(this.props.filter_by, 1)}} >
                        <span class="action-new-icon-sec">
                          <span class="icon-Exports action-new-icon"><span class="path1"></span><span class="path2"></span></span>
                        </span>
                        <span>Export List</span>
                      </button>
                      <hr />
                      {(this.CREATE_BUSSINESS_SNAPSHOT && this.SNAPSHOT_LEDGER_ENTRIES_CODE) && <>
                        <button onClick={async() => {
                         if (!!this.props.checkBulkFlag) {
                            if (await this.props.checkBulkFlag()) {
                              return true;
                            }
                          }
                        this.props.handleQuickEntryTabChange(1)} }>
                        <span class="action-new-icon-sec">
                          {/* <span class="icon-Group-4403 action-new-icon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span> */}
                          <span class="icon-invoice1 action-new-icon"></span>
                        </span>
                        <span>Snapshot Quick Entry</span></button>
                        <hr />
                      </>
                      }

                      {(this.CREATE_BUSSINESS_SNAPSHOT) && <>
                        <button onClick={async() => {
                          if (!!this.props.checkBulkFlag) {
                            if (await this.props.checkBulkFlag()) {
                              return true;
                            }
                          }
                          this.props.history.push('/snapshot/gstr2b')}} >
                          <span class="action-new-icon-sec">
                            {/* <span class="icon-invoice1 action-new-ico"><span class="path1"></span><span class="path2"></span></span> */}
                            <span className="icon-GST-icon icon-dt-td"><span class="path1 green-icon"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>
                          </span>
                          <span>GSTR2B</span>
                        </button>
                        <hr /></>
                      }

                     {(this.CREATE_BUSSINESS_SNAPSHOT) && <div > 
                        <button onClick={this.toggleImportExcelPopup} >
                          <span class="action-new-icon-sec">
                            <span class="icon-Exports action-new-icon"><span class="path1"></span><span class="path2"></span></span>
                          </span>
                          <span>Excel Import</span>
                        </button>
                        <hr />
                      </div>}

                      {(this.CREATE_BUSSINESS_SNAPSHOT) && <><button onClick={async() => {
                        if (!!this.props.checkBulkFlag) {
                          if (await this.props.checkBulkFlag()) {
                            return true;
                          }
                        }
                        this.props.history.push('/snapshot/bulk-action')}} >
                        <span class="action-new-icon-sec">
                          <span class="icon-delete action-new-icon"><span class="path1"></span><span class="path2"></span></span>
                        </span>
                        <span>Bulk Action</span>
                      </button>
                      <hr /> </>}

                      <button onClick={this.props.refreshItem}>
                        <span class="action-new-icon-sec"><span class="icon-refresh action-new-icon"></span></span>
                        <span>Refresh</span>
                      </button>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* End Actions Menu */}

                  {/* Bulk Upload Popup */}
                  {this.state.show_bulk_upload_popup && <BulkUploadPopup
                    toggleBulkUploadPopup={this.toggleBulkUploadPopup}
                    show_bulk_upload_popup={this.state.show_bulk_upload_popup}
                    finishBulkUpload={this.finishBulkUpload}
                    system_parameter={this.props.system_parameter}
                    snapshot_types={this.props.snapshot_types}
                    accounting_mode={this.props.accounting_mode}
                    entity_gst_no={this.props.entity_gst_no}
                    SMART_SCAN_SNAPSHOT={this.SMART_SCAN_SNAPSHOT}
                  ></BulkUploadPopup>}
                  {/* End Bulk Upload Popup */}


                  {/* Excel Import Popup */}
                  <ImportExcelPopup show_import_excel_popup={this.state.show_import_excel_popup} toggleImportExcelPopup={this.toggleImportExcelPopup} />
                  {/* <ImportExcelPopup></ImportExcelPopup> */}
                  {/* End Excel Import Popup */}

                </div>
              </div>

              {/* Filter Popup */}
              {(this.state.show_filter_popup || this.props.filter_by == 2) &&
                <SnapshotFilter
                  text={this.props.search_text}
                  reset={this.resetSearch}
                  applyFilter={this.props.applyFilter}
                  creaters={this.props.creaters}
                  listed_tags={this.props.listed_tags}
                  categories={this.props.categories}
                  financial_institutes={this.props.financial_institutes}
                  snapshot_types={this.props.snapshot_types}
                 
                />
              }
              {/* End Filter Popup */}
            </div>
          </div>
          {/* End Filter */}

          {/* Card Filter */}
           <ShowWidgets
           checkBulkFlag ={this.props.checkBulkFlag}
            default_cards={this.props.default_cards}
            onCardSelected={this.props.onCardSelected}
            filter_card={this.props.filter_card}
            type = "qucick_entry"
          />
          {/* End Card Filter */}

          {/* Snapshot List */}
          <span className='table_snapin'>
            <div className="row main-table-listing listing-icons">
              <div className="col-12">
                <div className="table-sec mb-0">
                  {this.props.snapshot_list.length === 0 && this.props.is_snapshot_loading === false ?
                    <h2 className="no-item-list">No items to display</h2> :
                    <BootstrapTable ref='table' 
                    data={this.props.snapshot_list} table-bordered trStyle={this.rowStyleFormat}
                    pagination={true}
                      options={options}
                      remote
                      fetchInfo={{ dataTotalSize: this.props.total_count }}
                    
                      condensed
                      selectRow={selectRowProp}
                     keyField='id'
                     wrapperClasses="table-responsive"
                    >
                      
                      <TableHeaderColumn width="" thStyle={{ textAlign: 'center', width: '55px' }} tdStyle={{ ...td_style, textAlign: 'center', width: '55px' }} dataField='is_star' dataFormat={(cell, row) => this.dataFormatStar(cell, row)}><SortingIcon onSortClick={this.onSortClick} field_name="Star" field_number="4" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
                      <TableHeaderColumn width="" thStyle={{ textAlign: 'center', width: '110px' }} tdStyle={{ ...td_style, textAlign: 'center', width: '110px' }}  dataField='date' dataFormat={this.displayDate} >
                        <SortingIcon onSortClick={this.onSortClick} field_name="Date" field_number="2" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /> </TableHeaderColumn>
                      <TableHeaderColumn width="" thStyle={{ width: '10%' }} tdStyle={{ ...td_style, width: '10%' }} dataField='type' columnClassName={"pepaidby"} dataFormat={(cell, row) => this.dataFormatType(cell, row)}><SortingIcon onSortClick={this.onSortClick} field_name="Type" field_number="3" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /> </TableHeaderColumn>
                      <TableHeaderColumn width="" thStyle={{ width: '19%' }} tdStyle={{ ...td_style, width: '19%' }} dataField='name' dataFormat={(cell, row) => this.displayCname(cell, row)}><SortingIcon onSortClick={this.onSortClick} field_name="Company" field_number="5" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /> </TableHeaderColumn>
                      <TableHeaderColumn width="" thStyle={{ width: '12%' }} tdStyle={{ ...td_style, width: '12%' }} dataField='category' dataFormat={(cell, row) => this.displayCategory(cell, row)} ><SortingIcon onSortClick={this.onSortClick} field_name="Category" field_number="9" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
                      <TableHeaderColumn width="" thStyle={{ textAlign: 'right', width: '12%' }} tdStyle={{ ...td_style, width: '12%', textAlign: 'right' }} dataField='total_amount' dataFormat={(cell, row) => this.displayAmount(cell, row)} ><SortingIcon onSortClick={this.onSortClick} field_name="Amount" field_number="8" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /> </TableHeaderColumn>
                      {/* <TableHeaderColumn width="" thStyle={{ width: '12%', textAlign: 'center', }} tdStyle={{ ...td_style, width: '12%', textAlign: 'center' }} dataField='payment_status' dataFormat={this.displayPaidStatus} ><SortingIcon onSortClick={this.onSortClick} field_name="Payment Status" field_number="6" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn> */}
                      <TableHeaderColumn width="" thStyle={{ width: '13%', textAlign: 'right', }} tdStyle={{ ...td_style, width: '13%', textAlign: 'right' }} dataField='payment_status' dataFormat={this.displayPendingAmount} ><SortingIcon onSortClick={this.onSortClick} field_name="Pending Amount" field_number="6" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>

                      <TableHeaderColumn width="" columnClassName={'tim-0'} tdStyle={{ ...td_style, textAlign: 'center', width: '6%' }} thStyle={{ textAlign: 'center', width: '6%' }} dataField='status' dataFormat={(cell, row) => this.dataFormatStatus(cell, row)}><SortingIcon onSortClick={this.onSortClick} field_name="Status" field_number="7" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /> </TableHeaderColumn>
                      <TableHeaderColumn width="200px" tdStyle={td_style} dataField='' dataFormat={(cell, row) => this.dataFormatTask(cell, row)} ></TableHeaderColumn>
                    </BootstrapTable>}
                </div>
              </div>
            </div>
          </span>
          {/* End Task List */}
          {this.props.snapshot_list.length != 0 &&
						<div className="fix-button-bar-bottom">
							{this.props.bulk_snapshot_list.length != 0 &&
								<button type="button"
									onClick={async () => { if (await confirm({ confirmation: "This action will clear the selection. Do you want to continue?" })) { this.resetSelection() } }}
									className={"transpnt-btn " + (this.props.bulk_snapshot_list.length == 0 ? " disabled" : "")}
								>Reset all</button>
							}
							{(this.state.show_select_all && this.props.total_count > 25)&&
								<button type="button" className={"transpnt-btn "} onClick={this.props.selectAllSnapshots}>Select across all pages</button>
            }

							<p className="fbb-p">Selected: {this.props.bulk_snapshot_list.length + "/" + this.props.total_count}</p>
							<div className="bottom-save-btn">
								{!!this.props.bulk_snapshot_list.length ?
									<Link to="/snapshot/quick-entry-create" className={"btn btn-success apply_btn_new"}>Add Entries</Link>
									:
									<button type="button" class="btn btn-success apply_btn_new disabled" disabled>Add Entries</button>
								}
							</div>
						</div>
					}
					{/* Listing Bottom Fixed Bar */}
        </div>

        <ChangeLog
          show_log={this.state.show_log}
          toggleChangeLog={this.toggleChangeLog}
          logs={this.state.logs_list}
          main={true}
        />
      </main>
    );
  }
}

function mapStateToProps(state) {
  const all_value = state.Snapshot || {};
  const is_snapshot_loading = all_value.is_snapshot_loading || false;
  const search_text = all_value.search_text || "";

  const session_values = state.Session || {};
  const features_permissions_list = session_values.features_permissions_list || [];
  const system_parameter = session_values.system_parameter || {};
  //accounting related configurations
  const accounting_mode = session_values.entity_config_param.accounting_mode || 1;
  const entity_gst_no = session_values.entity_config_param.gst_no || null;
  const bulk_snapshot_list = all_value.bulk_snapshot_list || [];
  return {
    is_snapshot_loading, features_permissions_list, search_text, all_value, system_parameter, accounting_mode, entity_gst_no,bulk_snapshot_list
  };
}

class Checkbox extends React.Component {
	componentDidMount() { this.update(this.props.checked); }
	componentWillReceiveProps(props) { this.update(props.checked); }
	update(checked) {
		ReactDOM.findDOMNode(this).indeterminate = checked === 'indeterminate';
	}
	render() {
		return (
			<input className='react-bs-select-all'
				type='checkbox'
				name={'checkbox' + this.props.rowIndex}
				id={'checkbox' + this.props.rowIndex}
				checked={this.props.checked}
				onChange={this.props.onChange} />
		);
	}
}

export default connect(mapStateToProps)(withRouter(SnapshotQuickEntry));