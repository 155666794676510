import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom.min";
import BreadCrum from "../../../Components/BoilerPlate/BreadCrum";
import {
  Form,
  OverlayTrigger,
  Table,
  Tooltip,
  Button,
  Dropdown,
} from "react-bootstrap";
import ReactSelect from "react-select";
import RouteLeavingGuard from "../../../Utils/RouteLeavingGuard";
import MoneyLinkService from "../../../Services/MoneyLink/MoneyLinkService";
import TransactionCard from "./TransactionCard";
import { decodeHtml } from "../../../Helpers/HelperFunction";
import { toast } from "react-toastify";
import { changeDateTimeFormat } from "../../../Helpers/DateFormat";
import { setBulkTransactionList } from "../../../Redux/Actions/MoneyLink";
import { setAppilcationLoder } from "../../../Redux/Actions/App";
import loadrew from '../../../Assets/img/buktec-preloader.gif'



class MoneylinkQuickView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollStep: 100,
      scrollTop: 0,
      scrollLeft: 0,
      is_dirty:false,
      next_route:false,
			closed:false,
			save_and_redirect:false,
      ledger_options : [],
      ledger_list:[],
      voucherTypeList:[],
      voucher_options:[],
      transaction_list:[],
      recommendation_data:[],
      isSelectFile:false

    };
	this.scrollRef = React.createRef();
  }

  
  saveBeforeLeave = (nextRoute) => {
		console.log("next",nextRoute)
		this.setState({ save_and_redirect: true, next_route: nextRoute },  () => {
			if (this.state.is_dirty) {
				 this.saveVoucher();
				this.setState({is_dirty:false},()=>{
					 this.props.history.push(nextRoute)
				})
				
			}
		});
	}
	closeAction = () => {
		this.setState({ closed: false })
	}

  closeTransactionLinking = () =>{
    this.props.history.goBack();
  }
  componentDidMount = async() =>{
      if(this.props.bulk_transaction_list.length  == 0){
          this.props. handleTabQuickView(1);
      }else{
        this.setState({isSelectFile:true})
        await this.ledgerNameList();
      }
  }
  ledgerNameList = async() =>{
    MoneyLinkService.getLedgerNameList().then(async (data)=>{
      if(data.response_code == 200){
        let ledger_options = data.result.ledger_list.map((ledger, index) => {
					return { label: decodeHtml(ledger.name), value: ledger.guid }
				})
        this.setState({ledger_options:ledger_options,ledger_list:data.result.ledger_list},()=>{
          this.getVoucherTypeList();
        })
      }
    })
  }

  getVoucherTypeList = async () =>{
		await MoneyLinkService.getVoucherTypeList().then(data => {
			if(data.response_code == 200){
        let voucher_options = data.result.voucher_types.map((ledger, index) => {
          return { label: decodeHtml(ledger.name), value: ledger.guid , is_master : ledger.is_master , parent_voucher_type_id:ledger.parent_voucher_type_id}
        })
				this.setState({voucherTypeList:data.result.voucher_types,voucher_options:voucher_options},()=>{
          if(this.props.bulk_transaction_list.length > 0){
              let selected_items = this.props.bulk_transaction_list.map(({id})=> id)
              this.setState({selected_items:selected_items},()=>{
                this.ledgerRecommendation(this.state.selected_items)
              })
          } 
        })
			}
		})
	}

  ledgerRecommendation = async(selected_items) =>{
    console.log("selected ",selected_items)
    await MoneyLinkService.getQuickEntryMoneylinkLedgerRecommendation(selected_items).then(async(data)=>{
      if(data.response_code == 200){
        console.log("data reco",data.result.recommendations)
          this.setState({recommendation_data:data.result.recommendations},()=>{
            this.preFillMoneylinkData();
          })
      }else{
        toast.error(data.message);
      }
    })
  }
  preFillMoneylinkData = () =>{
    let transaction_list = [...this.props.bulk_transaction_list];
    console.log(" prefill transaction list",transaction_list)
    transaction_list.map((item,index)=>{

          const recommend = this.state.recommendation_data.find(record => record.transaction_id == item.id);
          if(recommend != undefined){
              const party_ledger = this.state.ledger_list.find(ledger => ledger.guid == recommend.party_ledger_guid)
              if(party_ledger != undefined){
                item.party_ledger_guid = party_ledger.guid;
								item.party_ledger_name = party_ledger.name;
								item.party_ledger_id = party_ledger.id
              }else{
                console.log("ledger not fouded")
                item.party_ledger_guid = "";
								item.party_ledger_name = "";
								item.party_ledger_id = "";
              }

              if(item.type == 1){
                const voucher_type_details = this.state.voucherTypeList.find(voucher => (voucher.guid == recommend.voucher_type_guid) && (voucher.parent_voucher_type_id == 3) )
                if(voucher_type_details != undefined){
                    item.parent_voucher_type_id = voucher_type_details.parent_voucher_type_id
                    item.voucher_type_guid = voucher_type_details.guid
                    item.voucher_type_name = voucher_type_details.name
                    item.voucher_type_id =voucher_type_details.id
                }else{
                  console.log("voucher not founded")
                  item.parent_voucher_type_id = "";
                  item.voucher_type_guid = ""
                  item.voucher_type_name = ""
                  item.voucher_type_id = ""
                }
              }
              if(item.type == 2){
                const voucher_type_details = this.state.voucherTypeList.find(voucher => (voucher.guid == recommend.voucher_type_guid) && (voucher.parent_voucher_type_id == 2) )
                if(voucher_type_details != undefined){
                    item.parent_voucher_type_id = voucher_type_details.parent_voucher_type_id
                    item.voucher_type_guid = voucher_type_details.guid
                    item.voucher_type_name = voucher_type_details.name
                    item.voucher_type_id =voucher_type_details.id
                }else{
                  console.log("voucher not founded")
                  item.parent_voucher_type_id = "";
                  item.voucher_type_guid = ""
                  item.voucher_type_name = ""
                  item.voucher_type_id = ""
                }
              }
             
          }else{
            item.party_ledger_guid = "";
            item.party_ledger_name = "";
            item.party_ledger_id = "";
            item.parent_voucher_type_id = ""
            item.voucher_type_guid = ""
            item.voucher_type_name = ""
            item.voucher_type_id = ""
          }
console.log("bank ledger",this.props.bank_ledger_guid)
          if(this.props.bank_ledger_guid != undefined){
            if(this.props.bank_ledger_guid.ledger_guid != "" || this.props.bank_ledger_guid.ledger_guid != null){
              const bank_ledger = this.state.ledger_list.find(ledger => ledger.guid == this.props.bank_ledger_guid.ledger_guid)
              if(bank_ledger != undefined){
                item.bank_ledger_guid = bank_ledger.guid;
                item.bank_ledger_name = bank_ledger.name;
                item.bank_ledger_id = bank_ledger.id
              }else{
                item.bank_ledger_guid = "";
                item.bank_ledger_name = "";
                item.bank_ledger_id = "";
              }
            }
          }
         
          
          console.log("transction item",item)
          return item;
    })
    this.setState({transaction_list:transaction_list},()=>{
      setTimeout(() => {
        this.setState({isSelectFile:false})
      }, 1000);
    })
  }

  newHandleVoucherChange = (e,name,index) =>{
    let transaction_list = [...this.state.transaction_list]
      if(name == "select_voucher_guid"){
        if(e.value !== ""){
          let voucher_details = this.state.voucherTypeList.filter(voucher => voucher.guid == e.value);
          if(voucher_details.length > 0){
              transaction_list[index].parent_voucher_type_id = voucher_details[0].parent_voucher_type_id;
              transaction_list[index].voucher_type_guid = voucher_details[0].guid;
              transaction_list[index].voucher_type_name = voucher_details[0].name;
              transaction_list[index].voucher_type_id = voucher_details[0].id;
          }
        }
      }
      this.setState({transaction_list,is_dirty:true})
  }
  newUpdateLedgerLineData = (e,name,index)=>{
    let transaction_list = [...this.state.transaction_list]
    if(name == 'party_ledger'){
      if(e.value !== ""){
        const party_ledger = this.state.ledger_list.find((ledger) => ledger.guid == e.value)
        if(party_ledger != undefined){
          transaction_list[index].party_ledger_guid = party_ledger.guid;
          transaction_list[index].party_ledger_id = party_ledger.id;
          transaction_list[index].party_ledger_name = party_ledger.name;
        }else{
          transaction_list[index].party_ledger_guid = "";
          transaction_list[index].party_ledger_id = "";
          transaction_list[index].party_ledger_name = ""
        }
      }else{
        //for empty string
          transaction_list[index].party_ledger_guid = "";
          transaction_list[index].party_ledger_id = "";
          transaction_list[index].party_ledger_name = ""
      }
    }
    if(name == 'bank_ledger'){
      if(e.value !== ""){
        const bank_ledger = this.state.ledger_list.find((ledger) => ledger.guid == e.value)
        if(bank_ledger != undefined){
          transaction_list[index].bank_ledger_guid = bank_ledger.guid;
          transaction_list[index].bank_ledger_name = bank_ledger.name;
          transaction_list[index].bank_ledger_id = bank_ledger.id
        }else{
          transaction_list[index].bank_ledger_guid = "";
          transaction_list[index].bank_ledger_name = "";
          transaction_list[index].bank_ledger_id = ""
        }
      }else{
        //for empty string bank ledger
          transaction_list[index].bank_ledger_guid = "";
          transaction_list[index].bank_ledger_name = "";
          transaction_list[index].bank_ledger_id = ""
      }
    }
    this.setState({transaction_list,is_dirty:true})
  }

  isValidRecord = (record) =>{
    return record.party_ledger_guid && record.party_ledger_name && record.party_ledger_id && record.bank_ledger_guid &&
           record.bank_ledger_name && record.bank_ledger_name && record.voucher_type_guid && record.amount
  }
  createEntries = async() =>{
    let transaction_list = [...this.state.transaction_list];
				let remaining_id = [];
				let data = [];
    this.setState({isSelectFile:true})
        for(let i = 0; i< transaction_list.length ; i++){
            if(this.isValidRecord(transaction_list[i])){
              let ledgers = [];
              
              if(transaction_list[i].parent_voucher_type_id == 3 || transaction_list[i].type == 1){
                ledgers = [
                  {
                    "amount": transaction_list[i].amount,
                    "ledger_id":transaction_list[i].party_ledger_id,
                    "guid" : transaction_list[i].party_ledger_guid,
                    "name" : transaction_list[i].party_ledger_name,
                    "cost_categories": [],
                    "type":1
                  },
                  {
                    "amount": transaction_list[i].amount,
                    "ledger_id":transaction_list[i].bank_ledger_id,
                    "guid" : transaction_list[i].bank_ledger_guid,
                    "name" : transaction_list[i].bank_ledger_name,
                    "cost_categories": [],
                    "type":2
                  }
                ];
              }
              if(transaction_list[i].parent_voucher_type_id == 2 || transaction_list[i].type == 2){
                ledgers = [
                  {
                    "amount": transaction_list[i].amount,
                    "ledger_id":transaction_list[i].party_ledger_id,
                    "guid" : transaction_list[i].party_ledger_guid,
                    "name" : transaction_list[i].party_ledger_name,
                    "cost_categories": [],
                    "type":2
                  },
                  {
                    "amount": transaction_list[i].amount,
                    "ledger_id":transaction_list[i].bank_ledger_id,
                    "guid" : transaction_list[i].bank_ledger_guid,
                    "name" : transaction_list[i].bank_ledger_name,
                    "cost_categories": [],
                    "type":1
                  }
                ];
              }
              
             let  request_data = {
                "transaction_id":transaction_list[i].id,
                "accounting_narration":`${transaction_list[i].narration} | Quick Ledger Entry`,
                "parent_voucher_type_id": transaction_list[i].parent_voucher_type_id,
                "voucher_type_guid": transaction_list[i].voucher_type_guid,
                "voucher_type_name": transaction_list[i].voucher_type_name,
                "voucher_type_id": transaction_list[i].voucher_type_id,
                "voucher_creation_date":transaction_list[i].date,
                "total_credit_amount":transaction_list[i].amount,
                "total_debit_amount":transaction_list[i].amount,
                "ledgers":ledgers,
                // "sync_datetime": changeDateTimeFormat(new Date()),
                // "sync_status" : 0
              }

              data.push(request_data);
              remaining_id.push(transaction_list[i].id)
            }else{
              console.log("::::;unvalidated ",transaction_list[i])
            }
        }
        console.log("request data",data)
        if(data.length > 0){
          let voucher = { "vouchers": data };
          await MoneyLinkService.saveBulkLedger(voucher).then(async(data)=>{
            this.setState({isSelectFile:false})
            if(data.response_code == 200){
              toast.success("Moneylink ledger entries saved successfully");
                  const idsToRemove = new Set(remaining_id.map(item => item));
                  const data =  transaction_list.filter(item => !idsToRemove.has(item.id));
                  this.setState({transaction_list:data})
                  this.props.dispatch(setBulkTransactionList(data));
                  this.setState({is_dirty:false})
                  if(data.length == 0){
                    await this.props.refreshItem();
                    setTimeout(() => {
                      this.props.handleTabQuickView(1);
                    }, 1000);
                  }
            }else{
              toast.error(data.message)
            }
            
          })
        }else{
          this.setState({save_and_redirect: false,next_route: false,isSelectFile:false})
					
				
        
        }
    // if(request_data.voucher.length > 0){

    // }else{
    //   this.setState({save_and_redirect: false,next_route: false})
		// 	this.props.dispatch(setAppilcationLoder(false))
    // }
  }

  saveVoucher = async() =>{

    let transaction_list = [...this.state.transaction_list];
				let remaining_id = [];
				let data = [];
        this.setState({isSelectFile:true})
        for(let i = 0; i< transaction_list.length ; i++){
            if(this.isValidRecord(transaction_list[i])){
              let ledgers = [];
              
              if(transaction_list[i].parent_voucher_type_id == 3 || transaction_list[i].type == 1){
                ledgers = [
                  {
                    "amount": transaction_list[i].amount,
                    "ledger_id":transaction_list[i].party_ledger_id,
                    "guid" : transaction_list[i].party_ledger_guid,
                    "name" : transaction_list[i].party_ledger_name,
                    "cost_categories": [],
                    "type":1
                  },
                  {
                    "amount": transaction_list[i].amount,
                    "ledger_id":transaction_list[i].bank_ledger_id,
                    "guid" : transaction_list[i].bank_ledger_guid,
                    "name" : transaction_list[i].bank_ledger_name,
                    "cost_categories": [],
                    "type":2
                  }
                ];
              }
              if(transaction_list[i].parent_voucher_type_id == 2 || transaction_list[i].type == 2){
                ledgers = [
                  {
                    "amount": transaction_list[i].amount,
                    "ledger_id":transaction_list[i].party_ledger_id,
                    "guid" : transaction_list[i].party_ledger_guid,
                    "name" : transaction_list[i].party_ledger_name,
                    "cost_categories": [],
                    "type":2
                  },
                  {
                    "amount": transaction_list[i].amount,
                    "ledger_id":transaction_list[i].bank_ledger_id,
                    "guid" : transaction_list[i].bank_ledger_guid,
                    "name" : transaction_list[i].bank_ledger_name,
                    "cost_categories": [],
                    "type":1
                  }
                ];
              }
              
             let  request_data = {
                "transaction_id":transaction_list[i].id,
                "accounting_narration":transaction_list[i].narration,
                "parent_voucher_type_id": transaction_list[i].parent_voucher_type_id,
                "voucher_type_guid": transaction_list[i].voucher_type_guid,
                "voucher_type_name": transaction_list[i].voucher_type_name,
                "voucher_type_id": transaction_list[i].voucher_type_id,
                "voucher_creation_date":transaction_list[i].date,
                "total_credit_amount":transaction_list[i].amount,
                "total_debit_amount":transaction_list[i].amount,
                "ledgers":ledgers,
                // "sync_datetime": changeDateTimeFormat(new Date()),
                // "sync_status" : 0
              }

              data.push(request_data);
              remaining_id.push(transaction_list[i].id)
            }else{
              console.log("::::;unvalidated ",transaction_list[i])
            }
        }
        console.log("request data",data)
        if(data.length > 0){
          let voucher = { "vouchers": data };
          await MoneyLinkService.saveBulkLedger(voucher).then(async(data)=>{
            this.setState({isSelectFile:false})
            this.setState({is_dirty:false})
            if(data.response_code == 200){
              toast.success("Moneylink ledger entries saved successfully");
                  // const idsToRemove = new Set(remaining_id.map(item => item));
                  // const data =  transaction_list.filter(item => !idsToRemove.has(item.id));
                  // this.setState({transaction_list:data})
                  // this.props.dispatch(setBulkTransactionList(data));
                  // this.setState({is_dirty:false})
                  // if(data.length == 0){
                  //   await this.props.refreshItem();
                  //   setTimeout(() => {
                  //     this.props.handleTabQuickView(1);
                  //   }, 1000);
                  // }
                  this.setState({transaction_list:[]})
                  this.props.dispatch(setBulkTransactionList([]))
                  await this.props.refreshItem();
            }else{
                  toast.error(data.message)
                  this.setState({transaction_list:[]})
                  this.props.dispatch(setBulkTransactionList([]))
                  await this.props.refreshItem();
              
            }
            
          })
        }else{
          this.setState({save_and_redirect: false,next_route: false,isSelectFile:false})
					
				
        }

    // if(request_data.voucher.length > 0){

    // }else{
    //   this.setState({save_and_redirect: false,next_route: false})
		// 	this.props.dispatch(setAppilcationLoder(false))
    // }
  }


  scroll(direction) {
    const { scrollStep, scrollTop, scrollLeft } = this.state;
    const element = this.scrollRef.current;

    let newScrollTop = scrollTop;
    let newScrollLeft = scrollLeft;
    if (direction === "up") {
      newScrollTop = Math.max(scrollTop - scrollStep, 0);
    } else if (direction === "down") {
      newScrollTop = scrollTop + scrollStep;
    } else if (direction === "left") {
      newScrollLeft = Math.max(scrollLeft - scrollStep, 0);
    } else if (direction === "right") {
      newScrollLeft = scrollLeft + scrollStep;
    }

    // Update state and scroll position
    this.setState(
      { scrollTop: newScrollTop, scrollLeft: newScrollLeft },
      () => {
        if (element) {
          element.scrollTop = newScrollTop;
          element.scrollLeft = newScrollLeft;
        }
      }
    );
  }


  render() {
    console.log("transaction list",this.state.transaction_list)
    console.log("voucher type list",this.state.voucherTypeList)
    console.log("ledger option",this.state.ledger_options)
    return (
      <main className="content-wrapper-new">
        <RouteLeavingGuard
        when={this.state.is_dirty}
        saveChanges={this.saveBeforeLeave}
        closed={this.state.closed}
        closeAction={this.closeAction}
        resetForm={this.emptyStateValue}
        />
        <div className="container-fluid container-padding-new">
          {/* Breadcrumb */}
          <div className="row">
            <div className="col-12">
              <div className="main-sub-header-new">
                <div className="breadcrumb-header-new">
                  <div className="row">
                    <div className="col-12">
                      <BreadCrum level={2} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <h2 className="breadcrumb-title-new">Money Link</h2>
                    </div>
                    <div className="col-9 d-flex align-items-center justify-content-end gap-10">
                      {/* {(this.state.duplicate_cgst || this.state.duplicate_sgst || this.state.duplicate_igst)&&<span className='error_right mb-2'>Duplicate ledger(s) found. Please check snapshot entries and GST Ledgers.</span>} */}
                      <h2 className="breadcrumb-title-new sub-header-cicon">
                        {/* <Link onClick={() => this.closeTransactionLinking()}>
                          <span class="icon-Cross-with-grey-circle ">
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </span>
                        </Link>
                        </Link> */}
                        <Button variant="outline-success reset_btn_new" onClick={() => this.closeTransactionLinking()}>Back</Button>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrumb */}
           {/* Loader */}
        {this.state.isSelectFile && (
          <>
            <div className="ie_loader_overlay"></div>
            <img className="ie_loader_img" src={loadrew} />
          </>
        )}
        {/* End Loader */}


          {/* Table Design  */}
          {/* <div className="form-content-sec-new mt-0"> */}
          <div className="form-sec-inner-new">
            <div className="row">
              <div className="col-sm-12">
                {/* class import-excel */}
                <div className="import-excel-popup">
                  <div className="import-excel-border my-0">
                    <div className="import-excel-table" ref={this.scrollRef}>
                      <table className="table snap-excel-table-moneylink">
                        <colgroup>
                          <col></col>
                          <col></col>
                          <col
                            style={{ minWidth: "150px", maxWidth: "150px" }}
                          ></col>
                          <col className="td_set_selectie"></col>
                          <col className="td_set_selectie"></col>
                          <col className="td_set_amountie"></col>
                        </colgroup>

                        <thead>
                          <tr>
                            <th className="text-left">Transaction Date</th>
                            <th>Transaction Narration</th>
                            <th>Voucher Type
                              {/* {
                                this.state.voucherTypeList.length == 0 ? (
                                <OverlayTrigger overlay={<Tooltip id='tooltip-disabled'>
                                  <span className="red-color">
                                        Please sync with tally to get Voucher
                                        Types
                                      </span>
                                </Tooltip>}>
                                    
                                </OverlayTrigger>) : ("Voucher Type")
                              }{
                                this.state.voucherTypeList.length == 0 && (
                                  <span className="ie_error_dot ie_red_dot"></span>
                                )
                              } */}
                              {/* {this.state.voucherTypeList.length == 0 ? (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      <span className="red-color">
                                        Please sync with tally to get Voucher
                                        Types
                                      </span>
                                    </Tooltip>
                                  }
                                >
                              </OverlayTrigger>
                              ) : (
                                "Voucher Type"
                              )}
                              {this.state.voucherTypeList.length == 0 && (
                                <span className="ie_error_dot ie_red_dot"></span>
                              )} */}
                            </th>
                            <th>Party Ledger</th>
                            <th>Bank Ledger</th>                            
                            <th className="text-right">Total Amount</th>
                          </tr>
                        </thead>

                        <tbody>
                          {this.state.transaction_list.map((item, index) => {
                           return ( 
                          <tr
                            key={index}
                          >
                            <td className="ie_td_disabled text-left">
                           
                              {this.state.transaction_list[index].date}
                            </td>
                            <td className="ie_td_disabled">
                              <div className="ellipsisc">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                            this.state.transaction_list[index]
                                              .narration
                                          }
                                    </Tooltip>
                                  }
                                >
                                  <span>
                                    {
                                          this.state.transaction_list[index]
                                            .narration
                                        }
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </td>
                            {/* <td className="ie_td_disabled">{this.state.snapshot_list[index].voucher_type == 6 ? "Purchase" : "Sales"}</td> */}
                            <td className="p-0">
                              <div className="form-group mb-0">
                                {/* <select name="select_voucher_guid" className="form-control" 
																		value={this.state.snapshot_list[index].voucher_type_guid}
																		// disabled = {!this.state.is_gst_ledger}
																		onChange={(e) => this.handleVoucherChnage(e,index)} >
																		<option value="">Select Voucher</option>
																		{
																			this.state.voucherTypeList.filter(value => value.parent_voucher_type_id == this.state.snapshot_list[index].parent_voucher_type_id).map((value,ind)=>{
																				return (
																					<option key={ind} value={value.guid}>
																				{value.name}
																					</option>
																				);
																			})
																		}
																	</select> */}
                                <Form>
                                  <ReactSelect
                                    className="Select-Search typeed-dd "
                                    classNamePrefix="Select type-select "
                                    name="select_voucher_guid"
                                    // options={this.state.voucher_options.filter(
                                    //   (voucher) =>
                                    //     voucher.parent_voucher_type_id ==
                                    //     this.state.snapshot_list[index]
                                    //       .parent_voucher_type_id
                                    // )}
                                    options={this.state.voucher_options.filter(voucher => voucher.parent_voucher_type_id == (this.state.transaction_list[index].type == 1 ? 3 : 2))}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={true}
                                    value={this.state.voucher_options.filter(
                                      (option) =>
                                        option.value ==
                                        this.state.transaction_list[index]
                                          .voucher_type_guid
                                    )}
                                    onChange={(e) =>
                                      this.newHandleVoucherChange(
                                        e,
                                        "select_voucher_guid",
                                        index
                                      )
                                    }
                                    menuPlacement="auto"
                                  />
                                </Form>
                              </div>
                            </td>

                            <td className="p-0">
                              <div className="form-group mb-0">
                                <Form>
                                  <ReactSelect
                                    className="Select-Search typeed-dd "
                                    classNamePrefix="Select type-select "
                                    name="party_ledger"
                                    options={[
                                      { value: "", label: "Select" },
                                      ...this.state.ledger_options,
                                    ]}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={true}
                                    value={this.state.ledger_options.filter(
                                      (option) =>
                                        option.value ==
                                        this.state.transaction_list[index]
                                          .party_ledger_guid
                                    )}
                                    onChange={(e) =>
                                      this.newUpdateLedgerLineData(
                                        e,
                                        "party_ledger",
                                        index
                                      )
                                    }
                                    menuPlacement="auto"
                                  />
                                </Form>
                                <small className="error_right">
                                  {/* {this.state.snapshot_list[index]
                                        .duplicate_ledger
                                        ? "Duplicate ledger found"
                                        : ""} */}
                                </small>
                              </div>
                            </td>

                            <td className="p-0">
                              <div className="form-group mb-0">
                                <Form>
                                  <ReactSelect
                                    className="Select-Search typeed-dd "
                                    classNamePrefix="Select type-select "
                                    name="bank_ledger"
                                    options={[
                                      { value: "", label: "Select" },
                                      ...this.state.ledger_options,
                                    ]}
                                    isDisabled={true}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={true}
                                    value={this.state.ledger_options.filter(
                                      (option) =>
                                        option.value ==
                                        this.state.transaction_list[index]
                                          .bank_ledger_guid
                                    )}
                                    onChange={(e) =>
                                      this.newUpdateLedgerLineData(
                                        e,
                                        "bank_ledger",
                                        index
                                      )
                                    }
                                  />
                                </Form>
                                {/* <small className="error_right"> */}
                                  {/* {this.state.snapshot_list[index]
                                        .duplicate_ledger
                                        ? "Duplicate ledger found"
                                        : ""} */}
                                {/* </small> */}
                              </div>
                            </td>

                            
                            <td className="ie_td_disabled text-right">
                              {this.state.transaction_list[index].amount
                                    ? `₹ ${this.state.transaction_list[index].amount}`
                                    : ""}
                            </td>
                          </tr>
                           )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* end class import-excel */}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm-6 ie_arrows">
                {/* Arrows */}
                {/* <div className="ie_arrows"> */}
                <span
                  className="icon-right-arrow ie_arrows_icon ie_left"
                  onClick={() => this.scroll("left")}
                ></span>
                <span
                  className="icon-right-arrow-1 ie_arrows_icon ie_right"
                  onClick={() => this.scroll("right")}
                ></span>
                <span
                  className="icon-right-arrow-1 ie_arrows_icon pt-1"
                  onClick={() => this.scroll("down")}
                ></span>
                <span
                  className="icon-right-arrow ie_arrows_icon"
                  onClick={() => this.scroll("up")}
                ></span>
                {/* </div> */}
                {/* End Arrows */}
              </div>
              <div className="col-sm-6">
                <div className="reset_apply_btn_new">
                  <Button
                    variant="success apply_btn_new"
                    onClick={this.createEntries}
                    disabled={
                      this.state.transaction_list.filter((item) =>
                        this.isValidRecord(item)
                      ).length == 0
                    }
                  >
                    Create Entries
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* End Table Design  */}
        </div>
      </main>
    );
  }
}
function mapStateToProps(state) {
  const all_value = state.MoneyLink || {};
  const is_transaction_loading = all_value.is_transaction_loading;
  const filter_by = all_value.filter_by;
  const session_value = state.Session || {};
  const features_permissions_list =
    session_value.features_permissions_list || [];
  const bulk_transaction_list = all_value.bulk_transaction_list || [];
  const ledger_recommendation_param =
    session_value.entity_config_param.ledger_recommendation || 0;
  const financial_year_name = all_value.financial_year_name;
  const filter_card = all_value.filter_card;

  return {
    is_transaction_loading,
    filter_by,
    features_permissions_list,
    all_value,
    bulk_transaction_list,
    ledger_recommendation_param,
    financial_year_name,
    filter_card,
  };
}

export default connect(mapStateToProps)(withRouter(MoneylinkQuickView));
