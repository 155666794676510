import React from 'react'
import MasterService from '../../Services/MasterService';
import { toast } from 'react-toastify';
import StorageData from "../../Helpers/StorageData";
import DatePicker from "react-datepicker";
import { setFilterDateRange, setFinancialInstituteName, setFinancialYearName } from '../../Redux/Actions/MoneyLink';
import { connect } from 'react-redux';
import { changeDateFormate, DatePickerFormate, displayDateFormate, displayTimeFormat } from '../../Helpers/DateFormat';
import { Multiselect } from 'multiselect-react-dropdown';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { createConfirmation } from 'react-confirm';

class FinancialYearFilter extends React.Component {
	constructor(props) {
		super(props);


		let financial_years_arr = StorageData.getFinancialYear();
		let default_yr = StorageData.getDefaultFinancialYear();
		let financial_institute_id = StorageData.getDefaultFI();


		this.state = {
			year_type: default_yr.year_type,
			financial_years_arr: financial_years_arr ? financial_years_arr : [],
			// start_date: default_yr.year_type != 6 ? default_yr.start_date : "",
			// end_date: default_yr.year_type != 6 ? default_yr.end_date: "",
			start_date: default_yr.year_type == 6 ? default_yr.start_date : "",
			end_date: default_yr.year_type == 6 ? default_yr.end_date : "",
			financial_institute_list: [],
			financial_institute: this.props.bank_value || "",
			financial_institute_id: financial_institute_id || 0
		}
	}

	componentDidMount() {
		this.props.onRef(this);
		// this.getFinancialInstituteList()	
		// this.props.dispatch(setFinancialInstituteName(''))
		let financial_years_arr = StorageData.getFinancialYear();
		if (!financial_years_arr) {
			this.getFinancialYear();
		} else {
			this.setFinancialYearName()
		}
	}

	setFinancialYearName = () => {
		let defaultyear = StorageData.getDefaultFinancialYear()
		let name = this.state.financial_years_arr.find(x => x.id == defaultyear.year_type).name
		this.props.dispatch(setFinancialYearName(name))
	}

	getFinancialYear = () => {
		MasterService.getFinancialYear().then(
			(data) => {
				if (data.response_code == 200) {
					let fy = {
						id: 6,
						start_date: "",
						end_date: "",
						name: "Custom Date Range"
					};
					let financial_years_arr = [...data.result.date_filter, fy]
					this.setState({ financial_years_arr }, () => this.onYearTypeChange())
					// this.setState({
					//     financial_years_arr: data.result.date_filter
					// }, () => this.setStartEndDate());
					StorageData.setFinancialYear(financial_years_arr)
					this.setFinancialYearName()
				} else {
					toast.error(data.message);
				}
			});
	}



	onYearTypeChange = (year_type = 1) => {

		let start_date = this.state.financial_years_arr.find(x => x.id == year_type).start_date;
		let end_date = this.state.financial_years_arr.find(x => x.id == year_type).end_date;

		if (year_type == 6) {
			start_date = ""
			end_date = ""
		}

		this.setState({
			year_type: year_type,
			start_date: start_date ? new Date(start_date) : "",
			end_date: end_date ? new Date(end_date) : "",
		}, () => {
			if (year_type != 6) {
				StorageData.setDefaultFinacialYear(year_type);
				this.props.financialYearChanged(year_type, this.state.start_date, this.state.end_date);
			}
			// if(year_type == 6){
			// 	StorageData.setDefaultFinacialYear(year_type);
			// 	this.props.financialYearChanged(year_type, "", "");
			// }
			if(year_type == 6){
				this.props.dispatch(setFilterDateRange("",""))
			}
		}
		);
	}

	selectYearHandler = async (e) => {
		if (!!this.props.checkBulkLedgerFlag) {
			if (await this.props.checkBulkLedgerFlag()) {
				return true;
			}
		}
		this.onYearTypeChange(e.target.value)
	}

	resetFinancialInstitute = () => {
		this.setState({ financial_institute: null, financial_institute_id: 0 }, () => {
			this.props.financialInstituteChanged(null)
			this.props.dispatch(setFinancialInstituteName(null, null, null))
			StorageData.setDefaultFI(null);
		})
	}

	selectFinancialInstitute = async (e) => {
		let selectedItemId = e.target.value;

		if (!!this.props.checkBulkLedgerFlag) {
			if (await this.props.checkBulkLedgerFlag()) {
				return true;
			}
		}

		if (!!!selectedItemId && (self.location.pathname.includes("/money-link/bulk") || self.location.pathname.includes("/money-link/quick-entry"))) {
			toast.error("Please select single financial institute");
			return true;
		}
		let bank_ledger = this.props.financial_institute_list.find(bank => bank.id == selectedItemId)
		console.log("select bank",bank_ledger)
		 let valid_ledger = this.props.ledgerNameList.ledger_list.find(temp_ledger => {
			return  (temp_ledger.guid == bank_ledger.ledger_guid) || (temp_ledger.name == bank_ledger.ledger_name);
		 });
		 console.log("select bank",valid_ledger)
		if(self.location.pathname.includes("/money-link/quick-entry") && valid_ledger == undefined && valid_ledger?.guid == null){
			toast.error("This feature is available only after you have configured ledger in banking module.");
			return true;
		}

		if (!!!selectedItemId) {
			this.setState({ financial_institute: null, financial_institute_id: 0 }, () => {
				this.props.financialInstituteChanged(null)
				this.props.dispatch(setFinancialInstituteName(null, null, null))
				StorageData.setDefaultFI(null);
			})
		} else {
			this.setState({ financial_institute_id: selectedItemId }, () => {
				let name = ''
				let account_number = ''
				this.props.financialInstituteChanged(selectedItemId)
				StorageData.setDefaultFI(selectedItemId);
				if (selectedItemId)
					name = this.props.financial_institute_list.find(x => x.id == selectedItemId).bank_name
				account_number = this.props.financial_institute_list.find(x => x.id == selectedItemId).account_number

				this.props.dispatch(setFinancialInstituteName(name, selectedItemId, account_number))
			})
		}
	}

	/* 
	* comment here
	*/
	onFinancialInstituteChange = (e) => {
		this.setState({ financial_institute: e.target.value }, () => {
			let name = ''
			let account_number = ''
			this.props.financialInstituteChanged(e.target.value)
			if (e.target.value)
				name = this.props.financial_institute_list.find(x => x.id == e.target.value).bank_name
			account_number = this.props.financial_institute_list.find(x => x.id == e.target.value).account_number

			this.props.dispatch(setFinancialInstituteName(name, e.target.value, account_number))
		})
	}

	setStartEndDate = () => {
		if (this.state.financial_years_arr.length !== 0) {
			this.setState({
				start_date: new Date(this.state.financial_years_arr.find(x => x.id == this.state.year_type).start_date),
				end_date: new Date(this.state.financial_years_arr.find(x => x.id == this.state.year_type).end_date)
			}, () => {
				this.props.financialYearChanged(this.state.year_type, this.state.start_date, this.state.end_date);
			});
		}
	}

	setDate = (date, key) => {
		this.setState({ [key]: date }, () => {
			if (!!this.state.start_date && !!this.state.end_date) {
				let fy = {
					id: 6,
					start_date: changeDateFormate(this.state.start_date),
					end_date: changeDateFormate(this.state.end_date),
					name: "Custom Date Range"
				};
				let financial_years_arr = this.state.financial_years_arr.map(fin_year => {
					return (fin_year.id == 6) ? fy : fin_year;
				});
				StorageData.setDefaultFinacialYear(6);
				StorageData.setFinancialYear(financial_years_arr);
				this.props.financialYearChanged(this.state.year_type, this.state.start_date, this.state.end_date);
			}
		});
	}

	render() {
		return (<>
			<div className="filter-bar-new filter-bar-wrap dropdown_moneyin">
				{this.props.financial_institute_list.length != 0 ?
					<>
						{this.props.financial_institute_list.length != 0 ?
							<Select
								value={StorageData.getDefaultFI() || 0}
								onChange={this.selectFinancialInstitute}
								name="financial_institute"
								// className='mr-0'
								className="global_new_dd_date">
								{
									this.props.financial_institute_list.map((finance) => {
										return <MenuItem value={finance.id}>{finance.view_Name}</MenuItem>
									})
								}
							</Select> : null}
					</>
					: null
				}

				{this.state.financial_years_arr.length != 0 ?
					<>
						{this.state.financial_years_arr.length != 0 ?
							<Select
								value={this.state.year_type}
								onChange={this.selectYearHandler}
								name="year_type"
								// className='mr-0'
								className="global_new_dd_date">
								{
									this.state.financial_years_arr.map((year) => {
										return <MenuItem value={year.id}>{year.name}</MenuItem>
									})
								}
							</Select> : null}
					</>
					: null
				}

				<div className={(this.state.year_type == 6 ? "" : " d-none")}>
					<Select
						value={this.props.date_type}
						onChange={this.props.dateTypeChanged}
						name="year_type"
						className="global_new_dd_date">
						<MenuItem value="1">Transaction Date</MenuItem>
						<MenuItem value="2">Upload Date</MenuItem>
					</Select>
				</div>

				{/* <span className={this.state.year_type == 6 ? " gap-flt d-flex align-items-center " : "d-none"}> */}
				<span className={this.state.year_type == 6 ? " filter-tdates " : "d-none"}>
					<div className="start-end-date-sec">
						<DatePicker
							dateFormat={DatePickerFormate()}
							onChange={date => this.setDate(date, "start_date")}
							className="form-control field-height datepicker-icon"
							disabled={this.state.year_type == 6 ? "" : "disabled"}
							selected={this.state.start_date}
							maxDate={!!this.state.end_date ? new Date(this.state.end_date) : ""}
							autoComplete="off"
							placeholderText="From Date"
							name="start_date" />
					</div>
					<hr className="dash-flt" />
					<div className="start-end-date-sec">
						<DatePicker
							dateFormat={DatePickerFormate()}
							onChange={date => this.setDate(date, "end_date")}
							className="form-control field-height datepicker-icon"
							disabled={(this.state.start_date ? "" : "disabled")}
							selected={this.state.end_date}
							minDate={new Date(this.state.start_date)}
							autoComplete="off"
							placeholderText="To Date"
							name="end_date" />
					</div>
				</span>
			</div>
		</>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.MoneyLink || {};
	const bank_value = all_value.bank_value || null;

	return {
		bank_value

	};
}

export default connect(mapStateToProps)(FinancialYearFilter);