import React from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import { Link, withRouter, Prompt } from 'react-router-dom';
import { getRotatedImage, getZoomImage } from '../../Utils/canvasUtils';
import Dropzone from "react-dropzone";
import SnapshotService from '../../Services/Snapshot/SnapshotService';
import MasterService from '../../Services/MasterService';
import { toast } from 'react-toastify';
import StorageData from '../../Helpers/StorageData';
import pdficon from '../../Assets/img/pdficon.png'
import xmlIcon from '../../Assets/img/xml-icon.png'
import { SNAPSHOT_FILES_CODE } from '../../Helpers/Constants';
import { connect } from 'react-redux';
import { setAppilcationLoder } from "../../Redux/Actions/App";
import ShowPdfPopup from './ShowPdfPopup';
import { confirm } from '../../Components/BoilerPlate/Confirm';
import downloadicon from '../../Assets/img/downloadicon.svg';
import Button from 'react-bootstrap/Button';

class UploadFile extends React.Component {
	file = {};
	constructor(props) {
		super(props)
		this.state = {
			file_url: this.props.file_url,
			warningMsg: "",
			show_crop_view: false,
			is_crop: false,
			crop: {
				unit: '%',
				width: 30,
			},
			loading: false,
			uploading_msg: "",
			zoom_percentage: 100,
			height: null,
			width: null,
			file_name: "",
			file_type: "",
			gcp_subpath: "",
			isFullScreen: false,
			show_pdf_popup: false,
			show_uploaded_pdf_popup: false,
			buttonClicked: false,
		}

		// Create reference of DOM object
		this.imgRef = React.createRef()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.file_url !== this.state.file_url) {
			this.props.updateDisplayFileUrl(this.state.file_url)
		}
	}

	toggleImageFullView = () => {
		this.setState({
			show_crop_view: !this.state.show_crop_view,
			full_screen_file_url: this.state.file_url,
			isFullScreen: false,
			zoom_percentage: 100,
			height: null,
			width: null,
		})
	}

	changeCropMode = () => {
		this.setState({
			is_crop: !this.state.is_crop,
			zoom_percentage: 100,
			height: null,
			width: null,
		})
	}

	// onDrop = async (accepted, rejected) => {
	// 	if (Object.keys(rejected).length !== 0) {
	// 		const message = "Please submit valid file type";
	// 		this.setState({ warningMsg: message });
	// 	} else {
	// 		if (accepted[0].size > 1024 * 1000 * this.props.snapshot_filesize) {
	// 			const message = "Please select file below " + this.props.snapshot_filesize + "MB";
	// 			this.setState({ warningMsg: message });
	// 			return true;
	// 		}
	// 	}
	// 	this.file = accepted[0];
	// 	const file_name = accepted[0].name;
	// 	const file_type = accepted[0].type;

	// 	let blob = new Blob(accepted, { type: file_type });
		
	// 	console.log("blob", this.file)

	// 	const file_url = URL.createObjectURL(blob);

	// 	this.setState({ warningMsg: "", file_url, file_name, file_type });
	// 	// this.props.uploadFileUrl(accepted[0].name);

	// 	var blobPromise = new Promise((resolve, reject) => {
	// 		const reader = new window.FileReader();
	// 		reader.readAsDataURL(accepted[0]);
	// 		reader.onloadend = () => {
	// 			const base64data = reader.result;
	// 			resolve(base64data);
	// 		};
	// 	});
	// 	blobPromise.then(value => {
	// 		// console.log("value",value);
	// 	});


	// };

	onDrop = async (accepted, rejected) => {
		if (Object.keys(rejected).length !== 0) {
			const message = "Please submit a valid file type.";
			this.setState({ warningMsg: message });
			// return;
		} else {
			if (accepted[0].size > 1024 * 1000 * this.props.snapshot_filesize) {
				const message = "Please select a file below " + this.props.snapshot_filesize + "MB.";
				this.setState({ warningMsg: message });
				return true;
			}
		}
	
		const file = accepted[0];
		this.file = accepted[0];
		const file_name = accepted[0].name;
		const file_type = accepted[0].type;
	
		if (!["image/jpeg", "image/png", "image/jpg", "application/pdf", "application/xml", "text/xml"].includes(file_type)) {
			const message = "Unsupported file type. Please upload images, PDFs, or XML files.";
			this.setState({ warningMsg: message });
			return;
		}
		
		let blob = new Blob(accepted, { type: file_type });
			const file_url = URL.createObjectURL(blob);
	
			this.setState({ warningMsg: "", file_url, file_name, file_type });
	
			new Promise((resolve) => {
				const reader = new window.FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = () => resolve(reader.result);
			}).then((base64data) => {
				console.log("Base64 Data:", base64data);
			});
	};
	

	croppingCompleted = () => {
		this.setState({
			full_screen_file_url: this.state.croppedImageUrl,
			is_crop: !this.state.is_crop
		})
	}

	onSaveClick = () => {
		this.setState({
			file_url: this.state.full_screen_file_url,
			is_crop: false
		})
	}

	// If you setState the crop in here you should return false.
	onImageLoaded = image => {
		this.imageRef = image;
	};

	onCropComplete = crop => {
		this.makeClientCrop(crop);
	};

	onCropChange = (crop, percentCrop) => {
		// You could also use percentCrop:
		// this.setState({ crop: percentCrop });
		this.setState({ crop });
	};


	componentDidMount() {
		this.props.onRef(this);
	}

	async makeClientCrop(crop) {
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(
				this.imageRef,
				crop,
				this.state.file_name
			);
			this.setState({ croppedImageUrl });
		}
	}

	getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
		var dt = canvas.toDataURL('image/png');

		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve(this.fileUrl);
			}, 'image/jpeg');
		});
	}

	onRotate = async (rotation) => {
		let imageDataUrl = this.state.full_screen_file_url;
		if (rotation) {
			imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
		}
		this.setState({
			full_screen_file_url: imageDataUrl
		});
	}

	getZoomImage = async (zoomAction) => {

		// Fetching current height and width
		const height = this.imgRef.current.clientHeight + zoomAction * 10;
		const width = this.imgRef.current.clientWidth + zoomAction * 10;
		let zoom_percentage = this.state.zoom_percentage + zoomAction;
		if (zoom_percentage >= 60 && height > 310) {
			// Increase dimension(Zooming)
			this.setState({
				height,
				width,
				zoom_percentage: zoom_percentage
			})

		}

	}

	handleChange = async (e) => {
		this.props.handleChange(e);
		const is_checked = e.target.checked;
		if (is_checked && !!(this.state.gcp_subpath || this.props.qualified_url)) {
			if (await confirm({
				confirmation: 'The action will retrieve your prediction data and override your existing value. Do you still want to continue?'
			})) {
				if(this.props.is_new_model){
					this.newUploadImage();
				}else{
					const gcp_subpath = this.state.gcp_subpath || this.props.gcp_subpath;
					this.props.dispatch(setAppilcationLoder(true, "Fetching prediction data.."));
					await this.props.uploadFileUrl(gcp_subpath, this.state.file_type);
					this.props.dispatch(setAppilcationLoder(false));
				}
				
			} else {
				e.target.checked = false;
				this.props.handleChange(e);
			}
		}

	}

	parseXmlFile = async () => {
		if (this.state.file_type === "application/xml" || this.state.file_type === "text/xml") {
	
			const reader = new FileReader();
			reader.onload = async(event) => {
				try {
					let xmlContent = event.target.result;

                    xmlContent = xmlContent.replace(/&#4;/g, "");
					xmlContent = xmlContent.replace(/<(\w+)\.LIST>\s*<\/\1\.LIST>/g, "");

					const parser = new DOMParser();
					const xmlDoc = parser.parseFromString(xmlContent, "application/xml");

					console.log('xmlDoc', xmlDoc)

					console.log('snapshotType',this.state.snapshotType)
	
					const voucher = xmlDoc.getElementsByTagName("VOUCHER")[0];
					let referencedate = voucher.getElementsByTagName("REFERENCEDATE")[0]?.textContent || "";
	
					if (referencedate && referencedate.length === 8) {
						const year = referencedate.substring(0, 4);
						const month = referencedate.substring(4, 6);
						const day = referencedate.substring(6, 8);
						referencedate = `${day}-${month}-${year}`;
					}

					const ledgerEntries = voucher.getElementsByTagName("LEDGERENTRIES.LIST");
                    let partyLedgerAmount = null;
                    let totalSGST = 0;
                    let totalCGST = 0;
                    let totalIGST = 0;

                    for (let i = 0; i < ledgerEntries.length; i++) {
                        const isPartyLedger = ledgerEntries[i].getElementsByTagName("ISPARTYLEDGER")[0]?.textContent || "No";
                        if (isPartyLedger === "Yes") {
                            partyLedgerAmount = ledgerEntries[i].getElementsByTagName("AMOUNT")[0]?.textContent || null;
				    		if (partyLedgerAmount) {
                                partyLedgerAmount = Math.abs(parseFloat(partyLedgerAmount));
                            }
                            break;
                        }
                    }
					// Extract and sum amounts for LEDGERNAME containing "SGST"
                    for (let i = 0; i < ledgerEntries.length; i++) {
						const ledgerName = ledgerEntries[i].getElementsByTagName("LEDGERNAME")[0]?.textContent || "";

						if (ledgerName.includes("SGST") && !ledgerName.includes("CGST")) {
							const amount = ledgerEntries[i].getElementsByTagName("AMOUNT")[0]?.textContent || null;
							if (amount) {
								totalSGST += Math.abs(parseFloat(amount));
							}
						}
						if (ledgerName.includes("CGST") && !ledgerName.includes("SGST")) {
							const amount = ledgerEntries[i].getElementsByTagName("AMOUNT")[0]?.textContent || null;
							if (amount) {
								totalCGST += Math.abs(parseFloat(amount));
							}
						}
						if (ledgerName.includes("IGST")) {
							const amount = ledgerEntries[i].getElementsByTagName("AMOUNT")[0]?.textContent || null;
							if (amount) {
								totalIGST += Math.abs(parseFloat(amount));
							}
						}
                    }
					const inventoryEntries = voucher.getElementsByTagName("ALLINVENTORYENTRIES.LIST");
                    const lineItems = [];
                    for (let i = 0; i < inventoryEntries.length; i++) {
                        const stockItemName = inventoryEntries[i].getElementsByTagName("STOCKITEMNAME")[0]?.textContent || "";
                        const amount = inventoryEntries[i].getElementsByTagName("AMOUNT")[0]?.textContent || "0";
                        const rate = inventoryEntries[i].getElementsByTagName("RATE")[0]?.textContent || "0";
                        lineItems.push({
                            STOCKITEMNAME: stockItemName,
                            AMOUNT: Math.abs(parseFloat(amount)),
							RATE: rate
                        });
                    }

					const xmlData = {
						REFERENCEDATE: referencedate,
						REFERENCE: voucher.getElementsByTagName("REFERENCE")[0]?.textContent || "",
						PARTYGSTIN: voucher.getElementsByTagName("PARTYGSTIN")[0]?.textContent || "",
						PARTYNAME: voucher.getElementsByTagName("PARTYNAME")[0]?.textContent || "",
						AMOUNT: partyLedgerAmount,
						SGST: totalSGST,
						CGST: totalCGST,
						IGST: totalIGST,
						line_items: lineItems
					};
	
					this.setState({ xmlData });
					console.log('Parsed XML Data:', xmlData);
	
					const file = await MasterService.uploadFileFromBlobUrl(this.state.file_url);
					this.props.dispatch(setAppilcationLoder(true, ""));
					let signed_url_data = await MasterService.getSignedUploadFile(file, this.state.file_name, this.state.file_type, SNAPSHOT_FILES_CODE)
					if (signed_url_data == null) {
						signed_url_data = await MasterService.getSignedUploadFile(file, this.state.file_name, this.state.file_type, SNAPSHOT_FILES_CODE)
					}

					if (signed_url_data) {
						let gcp_subpath = signed_url_data.gcp_subpath;
						if (this.props.is_smart) {
							this.setState({
								uploading_msg: "Fetching prediction data.."
							});
							
							}
							this.props.handleXmlData(xmlData,gcp_subpath,this.state.file_type);
							this.setState({
								gcp_subpath,
								loading: false,
								uploading_msg: "Image uploaded.",
								
							})
							this.props.dispatch(setAppilcationLoder(false));
					}else{
						
							this.setState({
								loading: false,
								
							})
							this.props.dispatch(setAppilcationLoder(false));
							// await confirm ({
							//   confirmation: "Image not upload!!!.",
							//   options: { type: "alert" }
							// })
						
					}
					
					// this.props.newHandleXmlData(xmlData);
				} catch (error) {
					// this.setState({ warningMsg: "Error parsing XML file. Please check the file and try again." });
				}
			};
			reader.readAsText(this.file);
		}
	};

	uploadImage = async () => {
		console.log(":::::old model")
		this.setState({
			loading: true,
			uploading_msg: "Uploading...",
			
		});

		// if ((this.state.file_type != "image/jpeg" && this.state.file_type != "application/pdf" && this.state.file_type != "image/jpeg" && this.state.file_type != "image/png" && this.state.file_type != "image/jpg" && this.state.file_type != "application/xml" && this.state.file_type != "text/xml")) {
		// 	this.props.handleSmartToggle(false)
		// }

		// Handle file type and Smart Scan toggle
		if (this.state.file_type.includes("xml")) {
			this.props.handleSmartToggle(true); // Appear as if Smart Scan is ON
		} else if (
			this.state.file_type !== "image/jpeg" &&
			this.state.file_type !== "application/pdf" &&
			this.state.file_type !== "image/png" &&
			this.state.file_type !== "image/jpg"
		) {
			this.props.handleSmartToggle(false); // Smart Scan OFF for other unsupported types
		}

		if (this.state.file_type.includes("xml")) {
			// this.props.handleSmartToggle(false)
			await this.parseXmlFile();
			this.setState({
				loading: false,
				uploading_msg: "XML uploaded successfully.",
				
			});
			this.props.dispatch(setAppilcationLoder(false));
			return true;
		}

		const file = await MasterService.uploadFileFromBlobUrl(this.state.file_url);
		this.props.dispatch(setAppilcationLoder(true, "Uploading..."));
		let signed_url_data = await MasterService.getSignedUploadFile(file, this.state.file_name, this.state.file_type, SNAPSHOT_FILES_CODE)
		if (signed_url_data == null) {
			signed_url_data = await MasterService.getSignedUploadFile(file, this.state.file_name, this.state.file_type, SNAPSHOT_FILES_CODE)
		}
		if (signed_url_data) {
			let gcp_subpath = signed_url_data.gcp_subpath;
			if (this.props.is_smart) {
				this.setState({
					uploading_msg: "Fetching prediction data.."
				});
			}
			this.props.dispatch(setAppilcationLoder(true, "Fetching prediction data.."));
			await this.props.uploadFileUrl(gcp_subpath, this.state.file_type);
			this.setState({
				gcp_subpath,
				loading: false,
				uploading_msg: "Image uploaded.",
				
			})
			this.props.dispatch(setAppilcationLoder(false));
		} else {
			this.setState({
				loading: false,
				
			})
			this.props.dispatch(setAppilcationLoder(false));
			// await confirm ({
			//   confirmation: "Image not upload!!!.",
			//   options: { type: "alert" }
			// })
		}
	}

	//when we select new model will exceute AI-ML Wrapper api
	newUploadImage = async () => {
		if(this.props.is_smart){
		console.log(":::::new model")
		this.setState({
			loading: true,
			uploading_msg: "Uploading..."
		});

		if (this.state.file_type.includes("xml")) {
            await this.parseXmlFile();
            this.setState({
                loading: false,
                uploading_msg: "XML uploaded successfully.",
               
            });
            this.props.dispatch(setAppilcationLoder(false));
            return true;
        }

		if ((this.state.file_type != "image/jpeg" && this.state.file_type != "application/pdf" && this.state.file_type != "image/jpeg" && this.state.file_type != "image/png" && this.state.file_type != "image/jpg")) {
			this.props.handleSmartToggle(false)
			this.uploadImage();
		}else{
			const file = await MasterService.uploadFileFromBlobUrl(this.state.file_url);


			let types
			if(this.props.type == ""){
				types = 8
			}
			else{
				types = this.props.type
			}
			console.log("filetype",this.file)
			const formData = new FormData();
			formData.append('file',this.file);
			formData.append('snapshot_type',types);
			formData.append('expense',this.props.expense ? 1 : 0)

			formData.forEach((value, key) => {
				console.log(key, value);
			});
			
			this.props.dispatch(setAppilcationLoder(true, "fetching prediction data..."));
			if(this.props.is_smart){
				this.setState({uploading_msg : "Fetching prediction data.."})
			}

			await SnapshotService.newGetpredictionData(formData).then(
				async(data)=>{
					if(this.props.is_smart){
						this.setState({uploading_msg : "Fetching prediction data.."})
					}
					if(data.response_code == 200){
						this.props.dispatch(setAppilcationLoder(true, "Fetching prediction data.."));
						await this.props.newUploadFileUrl(data,this.state.file_type);
						this.setState({loading:false , uploading_msg: "Image uploaded."})
						this.props.dispatch(setAppilcationLoder(false));
		
					}
					else if(data.response_code == 400){
						toast.error(data.reason.file)
						this.setState({
							loading: false
						})
						this.props.dispatch(setAppilcationLoder(false, ""))
	
					}
					else{
						toast.error(data.message);
						this.setState({
							loading: false
						})
						this.props.dispatch(setAppilcationLoder(false, ""))
					}
					
				}
			)
		
		}

	}else{
		this.uploadImage()
	}
	}

	openPDF = () => {
		this.setState({ show_pdf_popup: true });
	}

	closePdfPopup = () => {
		this.setState({ show_pdf_popup: false });
	}
	openUploadedPDF = () => {
		this.setState({ show_uploaded_pdf_popup: true });
	}

	closeUploadedPdfPopup = () => {
		this.setState({ show_uploaded_pdf_popup: false });
	}

	removeFile = () => {
		//this.props.uploadFileUrl("");
		this.setState({
			file_url: null,
			gcp_subpath: null,
		});
		this.props.removeQualifiedUrl()
	}

	componentWillReceiveProps(nextProps) {
		if ((nextProps.match.params.snapshot_id !== this.props.match.params.snapshot_id)) {
			// if (!nextProps.match.params.snapshot_id) {
			this.removeFile()
			// }
		}
	}

	downloadFile = (file) => {
		const link = document.createElement('a');
		link.href = file;
		link.setAttribute(
			'download',
			'Snapshot-#S' + file.name,
		);

		// Append to html link element page
		document.body.appendChild(link);

		// Start download
		link.click();

		// Clean up and remove the link
		link.parentNode.removeChild(link);
	}

	render() {
		//console.log(this.props)
		console.log("newmodel",this.props.is_new_model)
		console.log("types ::::::::",this.props.type)
		console.log("expense:::::",this.props.expense)
		//console.log("upload:::",this.state.file_url)
		//console.log("this.props.is_reimbursement::", this.props.is_reimbursement)
		const imgStyle = { height: this.state.height, width: this.state.width };
		const editable = this.props.editable;
	
		return (<>
			<div className="col-xl-4 col-sm-12 mb-3 form-sec-inner-left">
				<div className="form-sec-inner-new quick_smart_snapcr">
					<div className="smart-scan-box-padding">
						{/* <div className="toggle-switch-btn toggle-switch-center">
							<span className="toggle-btn-sec-text mr-auto">Smart Scan</span>
							<span className="toggle-switch-center">
								<span className="toggle-btn-sec-text">No</span>
								<label className="switch">
									<input type="checkbox"
										checked={this.props.is_smart}
										onChange={(e) => this.handleChange(e)}
										name="is_smart"
										disabled={!this.props.SMART_SCAN_SNAPSHOT || !editable || (!!this.props.gcp_subpath && this.props.is_smart) || (this.state.file_type != "image/jpeg" && this.state.file_type != "application/pdf" && this.state.file_type != "image/jpeg" && this.state.file_type != "image/png" && this.state.file_type != "image/jpg" && this.state.file_type != "")} />
									<span className={"slider round " + (!this.props.SMART_SCAN_SNAPSHOT || !editable || (!!this.props.gcp_subpath && this.props.is_smart) ? "disable-any disable-opacity" : "")}></span>

								</label>
								<span className="toggle-btn-sec-text">Yes</span>
							</span>
						</div> */}

						<div className="toggle-switch-btn toggle-btn-bordered toggle-switch-center">
							<span className="toggle-btn-sec-text mr-auto">Smart Scan</span>
							<span className="toggle-switch-center">
								<span className="toggle-btn-sec-text">No</span>
								<label className="switch">
									<input type="checkbox"
										// checked={this.props.is_smart}
										// onChange={(e) => this.handleChange(e)}
										checked={this.props.is_smart || this.state.file_type.includes("xml")} // Appears checked for XML
        onChange={(e) => {
            if (!this.state.file_type.includes("xml")) {
                this.handleChange(e); // Only allow toggling if not XML
            }
        }}
										name="is_smart"
										disabled={!this.props.SMART_SCAN_SNAPSHOT || !editable || (!!this.props.gcp_subpath && this.props.is_smart) || (this.state.file_type != "image/jpeg" && this.state.file_type != "application/pdf" && this.state.file_type != "image/jpeg" && this.state.file_type != "image/png" && this.state.file_type != "image/jpg" && this.state.file_type != "")} />
									<span className={"slider round " + (!this.props.SMART_SCAN_SNAPSHOT || !editable || (!!this.props.gcp_subpath && this.props.is_smart) ? "disable-any disable-opacity" : "")}></span>

								</label>
								<span className="toggle-btn-sec-text">Yes</span>
							</span>
						</div>

						{/* <div className="d-flex align-items-center gap-10"> */}
						<div className="checks3 mt-3">
							{this.props.show_entity_mismatch_box && <>
								<div className="sup-cust-check">
									<input
										className="styled-checkbox"
										id="styled-checkbox-is_entity_mismatch"
										type="checkbox"
										name="is_entity_mismatch"
										checked={this.props.is_entity_mismatch}
										onChange={this.props.changeEntityMismacth}
										disabled={(this.props.VIEW_ONLY_SNAPSHOT || !editable )}
									/><label class={"lable-input" + ((this.props.VIEW_ONLY_SNAPSHOT || !editable) ? " disable-before-opacity" : " " ) } for="styled-checkbox-is_entity_mismatch">Entity Mismatch</label>
								</div></>
							}

							<div className="sup-cust-check">
								<input
									className="styled-checkbox"
									id="styled-checkbox-is_Perquisite"
									type="checkbox"
									name="is_perquisite"
									checked={this.props.is_perquisite}
									onChange={this.props.handleChange}
								disabled={this.props.VIEW_ONLY_SNAPSHOT || !editable || (!!!this.props.expense && !!!this.props.is_reimbursement)}
								/><label class={"lable-input" + ((this.props.VIEW_ONLY_SNAPSHOT || !editable || !!!this.props.expense) ? " disable-before-opacity" : " ") }for="styled-checkbox-is_Perquisite">Perquisite</label>
							</div>
							<div className="sup-cust-check">
								<input
									className="styled-checkbox"
									id="styled-checkbox-is_Reimbursement"
									type="checkbox"
									name="is_Reimbursement"
									checked={this.props.is_reimbursement}
									onChange={this.props.changeEntityReimbursement}
									disabled={this.props.VIEW_ONLY_SNAPSHOT || !editable ||(!this.props.create_reimbursement ||
									!this.props.create_business_snapshot)|| !!this.props.approvalStatus ||
									this.props.entryStatus == 2}
								/><label className={"lable-input" + (this.props.VIEW_ONLY_SNAPSHOT || !editable ||(!this.props.create_reimbursement ||
									!this.props.create_business_snapshot)|| !!this.props.approvalStatus ||
									this.props.entryStatus == 2 ? " disable-before-opacity" : "")}for="styled-checkbox-is_Reimbursement">Reimbursement</label>
									{/* disable-any disable-opacity */}
							</div>
						</div>

						<div className="form-group mt-2">
							<select
								className={"form-control " + (this.props.global_preidction_status && !!this.props.snapshot_type_prediction_status ? " greenc-light-bg " : "")}
								value={this.props.type}
								onChange={this.props.onTypeChange}
								disabled={this.props.VIEW_ONLY_SNAPSHOT || !editable || this.props.is_reimbursement}
								name="type">
								<option value="">Select Type</option>

								{
									this.props.snapshot_types.map((type, index) => {
										return <option disabled={type.expense == 0 && this.props.CREATE_REIMBUSRMENT_SNAPSHOT && !this.props.CREATE_BUSSINESS_SNAPSHOT} key={index} value={type.id}>{type.name}</option>
									})
								}
							</select>
							{this.props.errors.type && <small className="filter-error-msg">{this.props.errors.type}</small>}
						</div>


						{/* upblad box */}
						{
							this.props.qualified_url ?
								<div className="smart-scan-box">
									<div onClick={() => { this.state.loading ? "" : this.props.is_new_model ? this.props.newUploadFileUrl("") : this.props.uploadFileUrl(""); this.removeFile() }}
										className={"ss-cross " + (this.props.VIEW_ONLY_SNAPSHOT || !editable ? "d-none" : "")}>
										<span class="icon-Cross-with-grey-circle "><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
									</div>
									{
										this.props.qualified_url.indexOf(".pdf") != -1 ?
											// <img className="img-fluid mx-auto d-block" src={pdficon} onClick={() => this.openUploadedPDF()}/>
											<img className="img-fluid " src={pdficon} onClick={() => this.openUploadedPDF()} />
											:
											(this.props.qualified_url.indexOf(".jpg") != -1 || this.props.qualified_url.indexOf(".jpeg") != -1 || this.props.qualified_url.indexOf(".png") != -1) ?
												<img className="img-fluid " src={StorageData.getQualifiedFileUrl(this.props.qualified_url)}
													onClick={() => this.toggleImageFullView()}
												/>
												:
												this.props.qualified_url.indexOf(".xml") != -1 ?
												<img className="img-fluid" src={xmlIcon} style={{ width: "50px", height: "50px" }}/>
						                        :
												<span class="icon-Doc size-xxxvi" onClick={() => this.downloadFile(StorageData.getQualifiedFileUrl(this.props.qualified_url))}></span>
									}
								</div>
								:
								this.state.file_url ?
									<div>
										<div className="smart-scan-box">
											<div onClick={() => this.state.loading ? "" : this.removeFile()} className={"ss-cross " + (this.props.VIEW_ONLY_SNAPSHOT || !editable ? "d-none" : "")}>
												<span class="icon-Cross-with-grey-circle "><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
											</div>
											{
												(this.state.file_type == "application/pdf") ?
													<img className="img-fluid " src={pdficon} onClick={() => this.openPDF()} />
													:
													(this.state.file_type == "image/jpeg" || this.state.file_type == "image/png" || this.state.file_type == "image/jpg") ?
														<img className="img-fluid " src={this.state.file_url} onClick={() => this.toggleImageFullView()} />
														:
														this.state.file_type === "application/xml" || this.state.file_type === "text/xml" ? 
														<img className="img-fluid" src={xmlIcon} style={{ width: "50px", height: "50px" }}/>
														:
														<span class="icon-Doc size-xxxvi" style={{ cursor: "default" }}></span>
											}
										</div>
										{this.state.loading && <p className="lable-input text-center mb-2">{this.state.uploading_msg}</p>}
										{!!this.props.gcp_subpath ?
											// <p className="mb-2 ft-sz-12 font-colr text-center"></p>
											<></>
											:
											(
													<div className="center_apply_btn_new">
														<Button
															variant="success btn-blink"
															disabled={this.state.loading}
															onClick={this.props.is_new_model ? this.newUploadImage : this.uploadImage}
														>
															Upload
														</Button>
													</div>
												)
											// <div class="center_apply_btn_new">
											// 	<Button variant="success btn-blink" disabled={this.state.loading} onClick={this.props.is_new_model ? this.newUploadImage :this.uploadImage}>Upload</Button>
											// </div>
										}
										{(!!this.state.file_url && !!!this.props.gcp_subpath) &&
											<p className="error-msg mt-2 mb-2">
												{this.props.is_smart ? "Please click upload to get Prediction data" : "Please click upload button"}
											</p>
										}
									</div>
									:
									<Dropzone
										style={{
											width: "240px",
											height: "240px",
											objectFit: "cover",
											objectPosition: "center",
											border: " 1px dashed"
										}}
										multiple={false}
										disabled={this.props.VIEW_ONLY_SNAPSHOT || !editable}
										//accept="image/jpeg,image/jpg,image/png,application/pdf"
										accept="image/jpeg,image/jpg,image/png,application/pdf,application/xml,text/xml"
										onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
									>
										{({ getRootProps, getInputProps }) => (
											<div {...getRootProps()}>
												<input {...getInputProps()} />
												<div className="smart-scan-box ss-drag-border">
													<span className="icon-plus ssp-icon"></span>
													<p className="ssp-p">Drag Evidence</p>
													<p className="ssp-p">or click to attach</p>
												</div>
											</div>
										)}
									</Dropzone>
						}
						<p className="error-msg-center mb-2">{this.state.warningMsg}</p>
						{this.props.error && <p className="error-msg-center mb-2">{this.props.error}</p>}
						{/* upblad box */}
					</div>
				</div>

				{
					this.state.show_pdf_popup ? <ShowPdfPopup file_url={this.state.file_url || StorageData.getQualifiedFileUrl(this.props.qualified_url)} closePdfPopup={this.closePdfPopup} closePermission={this.props.VIEW_ONLY_SNAPSHOT || !editable} /> : null
				}
				{
					this.state.show_uploaded_pdf_popup ? <ShowPdfPopup file_url={StorageData.getQualifiedFileUrl(this.props.qualified_url)} closePdfPopup={this.closeUploadedPdfPopup} closePermission={this.props.VIEW_ONLY_SNAPSHOT || !editable} /> : null
				}

				{this.state.show_crop_view ?
					<div className={"smart-scan-popup " + (this.state.isFullScreen ? " fullscreen-img " : "")}>
						<div class="ssp-relative">
							{/* <div className={"main-smartview-sec dev-smartview-sec" + (this.state.isFullScreen ? " fullscreen-img " : "")}> */}
							<div onClick={() => this.toggleImageFullView()} className={"ss-cross " + (this.props.VIEW_ONLY_SNAPSHOT && !editable && false ? "d-none" : "")}>
								{/* <span>X</span> */}
								<span class="icon-Cross-with-grey-circle "><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
							</div>
							<div className="inner-smartview-sec">
								{
									this.state.is_crop ?
										<>
											{/* <div class="d-flex justify-content-center align-items-center minhimgcrop"> */}
											<ReactCrop
												src={this.state.full_screen_file_url}
												crop={this.state.crop}
												ruleOfThirds
												onImageLoaded={this.onImageLoaded}
												onComplete={this.onCropComplete}
												onChange={this.onCropChange}
											/>
											{/* // </div>  */}
										</>
										: <>
											{/* <div className="upper-img-sec d-flex justify-content-center align-items-center minhimgcrop"> */}
											{
												this.props.qualified_url ?
													<img className=" mx-auto d-block img-fluid" style={imgStyle} ref={this.imgRef} src={StorageData.getQualifiedFileUrl(this.props.qualified_url)} />
													: <img className=" mx-auto d-block img-fluid" style={imgStyle} ref={this.imgRef} src={this.state.full_screen_file_url} />
											}
											{/* </div> */}
										</>
								}
							</div>
							<div className="smartview-drag-sec">
								<div className="main-zoom-roted-sec">
									<div className="rotation-sec">
										<button type="button" disabled={this.state.is_crop || !!this.props.qualified_url ? "disabled" : ""} onClick={() => this.onRotate(-90)} className="btn"><span className="icon-refresh-11 blue-icon"></span></button>
										<button type="button" disabled={this.state.is_crop || !!this.props.qualified_url ? "disabled" : ""} onClick={() => this.onRotate(90)} className="btn"><span className="icon-refresh blue-icon"></span></button>
									</div>
									<div className="rotation-sec">
										<button type="button" onClick={() => this.getZoomImage(-10)} disabled={this.state.is_crop ? "disabled" : ""} className="btn"><span className="icon-magnifying-glass"></span></button>
										<span className="ft-sz-22 s-reg blu-color">{this.state.zoom_percentage}%</span>
										<button type="button" onClick={() => this.getZoomImage(10)} disabled={this.state.is_crop ? "disabled" : ""} className="btn"><span className="icon-zoom-in"></span></button>
									</div>
									<div className="rotation-sec">
										{this.state.is_crop ?
											<div>
												<button type="button" onClick={this.changeCropMode} className="btn">
													{/* <i className="fa fa-times" aria-hidden="true"></i> */}
													<span class="icon-cross--grey blue-icon"></span>
												</button>
												<button type="button" onClick={this.croppingCompleted} className="btn">
													<span className="icon-check"></span>
												</button>
											</div> :
											<button type="button" disabled={!!this.props.qualified_url ? "disabled" : ""} onClick={this.changeCropMode} className="btn">
												<span className="icon-Group-2197"></span>
											</button>
										}
									</div>
									<div className="rotation-sec">
										<button type="button" onClick={() => this.setState({ isFullScreen: !this.state.isFullScreen })} disabled={this.state.is_crop ? "disabled" : ""} className="btn"><span className="icon-full-size1"></span></button>
									</div>
									{
										!this.props.qualified_url &&
										<button type="button" onClick={this.onSaveClick} disabled={this.props.VIEW_ONLY_SNAPSHOT || !editable} className="btn btn-success apply_btn_new">Save</button>
									}
								</div>
							</div>
						</div>
					</div>
					:
					null
				}
			</div>
		</>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.Snapshot

	const session_value = state.Session || {};
	const system_parameter = session_value.system_parameter || {};
	const snapshot_filesize = system_parameter.snapshot_filesize || 0;
	const is_new_model  = session_value.entity_config_param.is_ner_model;

	return {
		snapshot_filesize,
		is_new_model
	};
}

export default connect(mapStateToProps)(withRouter(UploadFile));