import React from 'react'
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import LinkedStatus from '../IcoonNew/LinkedStatus';
import NotReadyStatus from '../IcoonNew/NotReadyStatus';
import ProcessedStatus from '../IcoonNew/ProcessedStatus';
import ReadyStatus from '../IcoonNew/ReadyStatus';
import SortingIcon from '../IcoonNew/SortingIcon';
import SyncedStatus from '../IcoonNew/SyncedStatus';
import Filter from './Filter';
import FinancialYearFilter from './FinancialYear';
import ShowWidgets from './ShowWidgets';
import MoneyLinkService from '../../Services/MoneyLink/MoneyLinkService';
import { connect } from 'react-redux';
import MessageIcon from '../IcoonNew/Message';
import TaskIcon from '../IcoonNew/Task';
import { displayDateFormate, displayTimeFormat } from '../../Helpers/DateFormat';
import IsPersonalIcon from '../IcoonNew/IsPersonal';
import EvidenceIcon from '../IcoonNew/EvidenceIcon';
import { LEDGER_ENTRIES_CODE, LINKING_CODE, VIEW_LINKED_ENTRIES_CODE } from '../../Helpers/Constants';
import { setAppilcationLoder } from "../../Redux/Actions/App";
import StorageData from "../../Helpers/StorageData";
import { changeDateFormate } from '../../Helpers/DateFormat';
import NotProcessedStatus from '../IcoonNew/NotProcessedStatus';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';
import { showAmount } from '../../Helpers/Currency';
import { checkFileExtension } from '../../Helpers/CheckValidation';
import ImportCostCenter from '../../Components/BoilerPlate/ImportCostCenter';
import ImportLedger from '../../Components/BoilerPlate/ImportLedger';
import ExportVouchers from '../../Components/BoilerPlate/ExportVouchers';
import ChangeLog from '../../Components/BoilerPlate/ChangeLog';
import MasterService from '../../Services/MasterService';
import { Button, Dropdown } from 'react-bootstrap';
import { ArrowUpDownIcon } from '../IcoonNew';
import { confirm } from '../../Components/BoilerPlate/Confirm';

// Intro js user guid
import { render } from "react-dom";
import { Steps } from "intro.js-react";
// End Intro js user guid 


/* static ui elements */
const td_style = { whiteSpace: 'normal', wordWrap: 'break-word' };

class MoneyLinkList extends React.Component {
	constructor(props) {
		super(props);
		this.btnRef = React.createRef();


		this.PERMISSION_LIST = this.props.features_permissions_list;
		this.LINKING_CODE = this.PERMISSION_LIST.includes(LINKING_CODE);
		this.LEDGER_ENTRIES = this.PERMISSION_LIST.includes(LEDGER_ENTRIES_CODE);
		this.VIEW_LINKED_ENTRIES = this.PERMISSION_LIST.includes(VIEW_LINKED_ENTRIES_CODE);
		this.state = {
			per_page_items: 0,
			showFilter: this.props.filter_by == 2,
			showSearch: true,
			active_input: false,
			search: '',
			is_permission_granted: (this.props.features_permissions_list.includes(LINKING_CODE) ||
				this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) ||
				this.props.features_permissions_list.includes(VIEW_LINKED_ENTRIES_CODE)),

			// Intro js user guid
			stepsEnabled: false,
			initialStep: 0,
			show_log: false,
			logs_list: {},
			cr_dr_type: this.props.cr_dr_type,
			steps: [
				{
					element: ".dropdown_moneyin",
					intro: "You can select a particular Financial Institute and specific time period or Select All.",
					// position: 'right',
					// hideNext: true 
				},
				{
					element: ".widgets_moneyin",
					intro: "These widgets, which also act as Filter once you click them, give high level status of the transactions based on the filter selected by you earlier."
				},
				{
					element: ".notready_usrin",
					intro: "It's like a draft mode where you have not added all the details."
				},
				{
					element: ".ready_usrin",
					intro: "This indicates that the Accountant can now pick this up for Processing."
				},
				{
					element: ".pending_usrin",
					intro: "You can see the Accounting status on this widget. Click on the right top corner to Toggle the status. **Pending – This shows transaction which are pending for accounting. **Accounted – This shows transaction for which accounting entry is done."
				},
				{
					element: ".synched_usrin",
					intro: "Once the entries are exported, they get automatically marked as synced.",
					position: 'left',
				},
				{
					element: ".linked_usrin",
					intro: "It indicated that the transaction is linked to a particular bank transaction. Use the toggle on top right to switch.",
					position: 'left',
				},
				{
					element: ".filter_moneyin",
					intro: "Use Filter to select specific types of transactions. Or do a Global search."
				},
				{
					element: ".table_moneyin",
					intro: "The table gives you a overview of the transaction along with status icon and others to indicate Evidence attachment, messages and tasks linked to it."
				}
			],
			// End Intro js user guid

		}

		this.filters = React.createRef();
		this.widgets = React.createRef();
	}

	displayAmount = (cell, row) => {
		return  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className=""><span className={row.type == 1 ? "green-color" : "red-color"}>{!!cell ? showAmount(cell) : ""}</span></Tooltip>}>
			<span className={row.type == 1 ? "green-color" : "red-color"}>{!!cell ? showAmount(cell) : ""} {!!cell ? <ArrowUpDownIcon expense={ (row.type == 1) ? false : true} /> : ""}</span>
		</OverlayTrigger>
	}

	toggleFilter = () => {
		this.setState({ showFilter: !this.state.showFilter })
	}

	makeRemoveStar = (e, row) => {
		e.stopPropagation();
		let data = {
			id: row.id,
			is_star: row.is_star !== 1 ? 1 : 0
		}
		this.props.makeRemoveStar(row.id, data.is_star)
		MoneyLinkService.makeRemoveStar(data).then(
			(data) => {
				if (data.response_code == 200) {
				} else {
					this.props.makeRemoveStar(row.id, row.is_star)
					toast.error(data.message);
				}
			});
	}

	dataFormatStar = (cell, row) => {
		switch (cell) {
			case 1:
				return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td active"></span></span>

			case 0:
				return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td"></span></span>
			default:
				return <span onClick={(e) => this.makeRemoveStar(e, row)}><span className="icon-Star star-td"></span></span>
		}
	}

	dataFormatStatus = (cell, row) => {
		switch (cell) {
			case 1:
				return <NotReadyStatus />
			case 2:
				return <ReadyStatus />
			case 3:
				return <NotProcessedStatus />
			case 4:
				return <ProcessedStatus />
			case 5:
				return <SyncedStatus />
			default:
				return "--"
		}
	}

	displayDate = (cell, row) => {
		return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{"ID #" + row.id} {'Uploaded At: ' + row.created_at}</Tooltip>}>
			<span>{displayDateFormate(cell)}</span>
		</OverlayTrigger>
	}

	displayFinantial = (cell, row) => {
		return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{cell}</Tooltip>}>
			<div className="ellips-auto">{cell}</div>
		</OverlayTrigger>
	}

	displayNarration = (cell, row) => {
		return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">{cell}</Tooltip>}>
			<div className="ellips-auto">{cell}</div>
		</OverlayTrigger>
	}

	dataFormatType = (cell, row) => {
		switch (cell) {
			case 1:
				return "CR"
			case 2:
				return "DR"
			default:
				return "--"
		}
	}

	dataFormatPersonal = (cell, row) => {
		return <IsPersonalIcon value={cell} />
	}

	goToEditView = (row) => {
		if (this.state.is_permission_granted) {
			this.props.history.push({
				pathname: `/money-link/` + row.id,
				state: {
					search: this.state.search
				}
			});
		}

	}

	onSortClick = (sort_order, sort_by) => {
		this.props.onSortClick(sort_order, sort_by)
	}

	handleChange = (e) => {
		e.preventDefault();
		// this.setState({ search: e.target.value }, () => {
		// 	// if(this.state.search!=='' && this.state.showSearch===false){
		// 	// 	this.setState({showSearch:true})
		// 	// }
		// 	// if(this.state.search!=='' && this.state.showSearch===true){
		// 	// 	this.setState({showSearch:false})
		// 	// }
		// })
		this.props.changeSearchText(e.target.value)
	}

	checkForEnterButton = (e) => {
		// e.preventDefault();
		if (e.charCode === 13 && e.target.value) {
			this.searchFilter(e)
		}
		// else if(e.charCode === 13 && !e.target.value){
		// 	toast.error("Enter search text")
		// 	return
		// }
		else {
			// this.setState({ search: e.target.value })
			this.props.changeSearchText(e.target.value)
		}
	}

	searchFilter = (e) => {
		e.preventDefault();
		if (this.props.searchText) {
			this.props.searchFilter(this.props.searchText);
			this.props.changeSearchStatus(true);
			this.setState({active_input: true});
			this.setState({ showSearch: false, showFilter: false })
			if (this.props.filter_by == 2) {
				toast.warning('Removed filter data and applied search filter')
			}
		}
		else {
			toast.error("Please specify search criteria")
		}
	}

	searchReset = () => {
		this.setState({
			search: '',
			showSearch: true,
			active_input: false
		}, () => {
			this.props.changeSearchText("")
			this.props.changeSearchStatus(false)
		})
	}

	resetFilter = (e) => {
		e.preventDefault();
		this.setState({
			search: '',
			showSearch: true,
			active_input: false
		}, () => {
			this.props.searchFilter("");
			this.props.changeSearchStatus(false)
		})
	}

	handleSizePerPageChange(sizePerPage) {
		// When changing the size per page always navigating to the first page
		this.setState({
			per_page_items: sizePerPage
		}, () => this.getInvoiceList(this.state.filter_by))
	}

	_setNoDataFoundOption() {
		if (!this.props.is_transaction_loading) {
			return "No Transaction found";
		} else {
			return (
				<p style={{ position: 'relative', margin: '0px auto' }}>Loading ...</p>
			);
		}
	}

	dataFormatTask = (cell, row) => {
		return <div>
			<EvidenceIcon active={row.has_evidence} />
			{/* <MessageIcon active={row.general_msg_status || row.private_msg_status} /> */}
			<TaskIcon active={row.tasks_status || row.general_msg_status || row.private_msg_status} />
		</div>
	}

	// showFilter = () => {
	// 	if (this.props.filter_by == 2) {
	// 		this.setState({ showFilter: true })
	// 	}
	// }

	componentDidMount = async () => {
		if (!!this.props.location.state) {
			if (!!this.props.location.state.showalert) {
				confirm({
					confirmation: "Unable to show specified ID. Please check active entity and ID.",
					options: { type: "alert" }
				})
				window.history.replaceState({}, document.title)
			}
		}

		// this.showFilter()
		this.props.onRef(this);
		StorageData.deleteTransactionId()


	}

	renderShowsTotal(start, to, total) {
		return (
			<p className="main-pagi-show">
				{/* Displaying from <span className="pagi-show">{start}</span> to <span className="pagi-show">{to}</span>, totals is <span className="pagi-show">{total}</span> */}

				<span className="pagi-show">{start}</span> to <span className="pagi-show">{to}</span> of <span className="pagi-show">{total}</span>
			</p>
		);
	}

	downloadVouchers = () => {
		let defaultYr = StorageData.getDefaultFinancialYear();

		let dateRange = {
			"date_filter": {
				start_date: changeDateFormate(defaultYr.start_date),
				end_date: changeDateFormate(defaultYr.end_date)
			},
			"module": 4
		}

		this.props.dispatch(setAppilcationLoder(true));
		MoneyLinkService.downloadVouchers(dateRange).then((blob) => {
			
			if (blob != 404) {
				var dataa = new Blob([blob], { type: 'text/xml' });
				let url = window.URL.createObjectURL(dataa);
				let a = document.createElement('a');
				a.href = url;
				a.download = "vouchers.xml";
				a.click();

				this.props.refreshItem()
			} else {
				toast.error("No vouchers found");
			}
			this.props.dispatch(setAppilcationLoder(false));
		});
	}

	// Intro js user guid   
	toggleSteps = () => {
		this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
	};
	onExit = () => {
		this.setState(() => ({ stepsEnabled: false }));
	};
	// End Intro js user guid

	getLogs = () => {
		this.props.dispatch(setAppilcationLoder(true));
		let detail = { mod_id: null, module: 4 }
		MasterService.getLogs(detail).then((data) => {
			this.props.dispatch(setAppilcationLoder(false));
			if (data.response_code === 200) {
				this.setState({ logs_list: data.result.change_logs })
			} else {
				toast.error(data.message)
			}
			this.setState({ show_log: true })
		})
	}

	toggleChangeLog = () => {
		this.setState({ show_log: !this.state.show_log })
	}

	trStyleChange(row, sort_order) {
		console.log("tr style change -----------" , row);
		if (row && !!row.is_missing_data == 1) {
		  let line_position = sort_order == 1 ? "-8px" : "8px";
		  return {
			//backgroundColor: '#fbceb1',
			//borderWidth:"1px", borderColor:"red", borderStyle:'solid'
			//boxShadow: "0 7px 5px -6px red"
			//boxShadow: "1px 3px 1px #9E9E9E"
			boxShadow: "0 " + line_position + " 4px -5px red"
		  }
		}
		else {
		  return null
		}
	  }


	render() {

		// Intro js user guid 
		const {
			stepsEnabled,
			steps,
			initialStep,
			hintsEnabled,
			hints
		} = this.state;
		// End Intro js user guid 

		const options = {
			onPageChange: this.props.handlePageChange,
			onSizePerPageList: this.handleSizePerPageChange,
			page: this.props.page_no,  // which page you want to show as default
			sizePerPage: this.props.per_page_items,  // which size per page you want to locate as default
			pageStartIndex: 1, // where to start counting the pages
			paginationSize: 5,  // the pagination bar size.
			prePage: 'Prev', // Previous page button text
			nextPage: 'Next', // Next page button text
			firstPage: 'First', // First page button text
			lastPage: 'Last', // Last page button text
			//paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
			hideSizePerPage: true, //> You can hide the dropdown for sizePerPage
			onRowClick: (row) => {
				this.goToEditView(row);
			},
			noDataText: this._setNoDataFoundOption(),
		}

		return (
			<main className="content-wrapper-new">
				<div className="container-fluid container-padding-new">
					{/* Intro js user guid */}
					<Steps
						enabled={stepsEnabled}
						steps={steps}
						initialStep={initialStep}
						onExit={this.onExit}
					/>
					{/* End Intro js user guid */}

					{/* Breadcrumb */}
					<div className="row">
						<div className="col-12">
							<div className="main-sub-header-new">
								<div className="breadcrumb-header-new">
									<div className="row">
										<div className="col-12">
											<BreadCrum level={2} />
										</div>
									</div>
									<div className="row">
										<div className="col-xl-8 col-6">
											<h2 className="breadcrumb-title-new">
												Money Link - Single Entry
												{/* <span className="help-icon" > */}
													<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Help</Tooltip>}><span className="icon-Information help-icon" onClick={this.toggleSteps}></span></OverlayTrigger>
												{/* </span> */}
											</h2>
										</div>
										<div className="col-xl-4 col-6">
											<h2 className="breadcrumb-title-new sub-header-cicon">
												<span className="size-xvii gray-text s-sbold mr-2">
													{(this.props.bank_value == null) ? '' : (this.props.closing_balance == null) ? 'Closing Balance: N/A' : 'Closing Balance: ' + showAmount(this.props.closing_balance)}
												</span>
												<span className="icon-round-info-button icon-log cpointer" onClick={this.getLogs}></span>
											</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* End Breadcrumb */}

					{/* Filter */}
					<div className="row mainFilter-new sec-mb">
						<div className="col-12">
							<div className="top-new-filter">
								<div className="d-flex flex-wrap align-items-center gap-10">
									<FinancialYearFilter
										financialYearChanged={this.props.financialYearChanged}
										refreshItem={this.props.refreshItem}
										hideRefreshItem={true}
										freshRecommendation={this.props.freshRecommendation}
										financialInstituteChanged={this.props.financialInstituteChanged}
										recommendation_meta={this.props.recommendation_meta}
										financial_institute_list={this.props.financial_institute_list}
										onRef={ref => (this.filters = ref)}
										dateTypeChanged={this.props.dateTypeChanged}
										date_type={this.props.date_type}
										ledgerNameList = {this.props.ledgerNameList}
									/>									
									{
										this.props.gap_count != 0 && this.props.gap_count ? <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">{this.props.gap_count} - Gap{this.props.gap_count>1?'s':''} found in the statement:<span className="gap-count-date">&nbsp;{this.props.gap_date}</span></Tooltip>}>
										{/* <span className="menu-gap-count">2</span> */}
										<div className="gap-count-note">
											<span className="gap-count-badge">{this.props.gap_count}</span>
										</div>
									</OverlayTrigger>:null
									}
									
								</div>

								<div className="filter-search-field">
								 <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Narration</Tooltip>}>
										<form className={"search-new-sec" + (this.props.searchApplied ? " active-filter" : "")}>
											<input type="text"
												className={"form-control filter-btn-new " + (this.state.active_input ? " activeNew-search" : "")}
												onKeyPress={this.checkForEnterButton}
												onFocus={() => this.setState({ active_input: true })}
												//onBlur={() => this.setState({ active_input: false })}
												placeholder="Search"
												value={this.props.searchText}
												onChange={(e) => this.handleChange(e)}
												name="search" />

											{this.props.searchApplied == false ?
												<Button onClick={this.searchFilter} className={this.state.active_input ? " active-new-search-icon " : ""}>
													<span className="icon-loupe"></span>
												</Button>
												:
												<Button onClick={this.resetFilter} className={this.state.active_input ? " active-new-search-icon crosebtn " : " crosebtn "}>
													<span class="icon-cancel"></span>
												</Button>
											}
										</form>
									</OverlayTrigger>

									{/* <div className={"filter filter_moneyin mr-1 " + (this.state.showFilter ? "active-class" : "")} onClick={this.toggleFilter}>Filter <span className="icon-funnel"></span></div> */}
									{/* {this.props.transaction_list.length === 0 && this.props.is_transaction_loading === false ? '' : <Button variant={(this.props.filter_by == 2) ? " outline-secondary filter-btn-new border-radius-26 active-filter filter_snapin filter_moneyin " : " outline-secondary filter-btn-new border-radius-26 filter_moneyin"}
										onClick={this.toggleFilter}
									>Filter <span className="icon-funnel gray-dark-icon filter-icon"></span></Button>
									} */}
									<Button variant={(this.props.filter_by == 2) ? " outline-secondary filter-btn-new border-radius-26 active-filter filter_snapin filter_moneyin " : " outline-secondary filter-btn-new border-radius-26 filter_moneyin"}
										onClick={this.toggleFilter}
									>Filter <span className="icon-funnel gray-dark-icon filter-icon"></span></Button>
									

									{/* Actions Menu */}
									<Dropdown className='action-new-menu'>
										<Dropdown.Toggle variant="" id="dropdown-basic" className='success add-new-btn action-btn-height'>
											<span class="icon-menu-lines white-icon"></span>
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{this.LEDGER_ENTRIES &&
												<>
													<button onClick={() => this.props.handleTabChange(0)} >
														<span class="action-new-icon-sec">
															<span class="icon-Group-2546 action-new-icon"></span>
														</span>
														<span>Single Entry</span>
													</button>
													<button onClick={() => this.props.handleTabChange(1)} >
														<span class="action-new-icon-sec">
															<span class="icon-Group-2546 action-new-icon"></span>
														</span>
														<span>CR Bulk Entries</span>
													</button>
													<button onClick={() => this.props.handleTabChange(2)} >
														<span class="action-new-icon-sec">
															<span class="icon-Group-2546 action-new-icon"></span>
														</span>
														<span>DR Bulk Entries</span>
													</button>
													<button onClick={() => this.props.handleTabChange(4)} >
														<span class="action-new-icon-sec">
															<span class="icon-invoice1 action-new-icon"></span>
														</span>
														<span>Moneylink Quick Entries</span>
													</button>
													<button onClick={() => this.props.handleTabChange(3)} >
														<span class="action-new-icon-sec">
															{/* <span class="icon-Group-2546 action-new-icon"></span> */}
															<span class="icon-Group-2516 action-new-icon"></span>
														</span>
														<span>Transaction Reset</span>
													</button>
													<hr />
												</>
											}
											{/* <button disabled={this.props.recommendation_meta.initiate_flag == 0 ? 'disabled' : ''} onClick={() => { this.props.freshRecommendation() }} > */}
											<button onClick={() => { this.props.freshRecommendation() }} >
												<span class="action-new-icon-sec">
													<span class="icon-invoice1 action-new-icon"></span>
												</span>
												<span class={this.props.recommendation_meta.initiate_flag == 0 ? 'disable-any' : ''}>Initiate Fresh Recommendation
													<span className="d-block blue-text">{displayDateFormate(this.props.recommendation_meta.last_run)} {displayTimeFormat(this.props.recommendation_meta.last_run)}</span>
												</span>
											</button>
											<hr />
											<button onClick={() => this.props.getTransactionList(this.props.filter_by, 1)} >
												<span class="action-new-icon-sec">
													<span class="icon-Exports action-new-icon"><span class="path1"></span><span class="path2"></span></span>
												</span>
												<span>Export List</span>
											</button>
											<hr />
											<button onClick={this.props.refreshItem}>
												<span class="action-new-icon-sec">
													<span class="icon-refresh action-new-icon"></span>
												</span>
												<span>Refresh</span>
											</button>
										</Dropdown.Menu>
									</Dropdown>
									{/* End Actions Menu */}
								</div>
							</div>

							{/* Filter Popup */}
							{(this.state.showFilter || this.props.filter_by == 2)&& <Filter
								reset={this.searchReset}
								search={this.props.searchText}
								modified_by_list={this.props.modified_by_list}
								applyFilter={this.props.applyFilter}
								resetFinancialInstitute={() => this.filters.resetFinancialInstitute()}
								onCardSelected={(val) => this.widgets.onCardSelected(val)}
							/>}
							{/* End Filter Popup */}
						</div>
					</div>
					{/* End Filter */}

					{/* Card Filter */}
					<ShowWidgets
						default_cards={this.props.default_cards}
						filter_card={this.props.filter_card}
						onCardSelected={this.props.onCardSelected}
						transaction_count={this.props.transaction_count}
						features_permissions_list={this.props.features_permissions_list}
						onRef={ref => (this.widgets = ref)}
					/>
					{/* End Card Filter */}

					{/* Table List */}
					<span className='table_moneyin'>
						<div className="row main-table-listing listing-icons">
							<div className="col-12">
								<div className="table-sec mb-0">
									{this.props.transaction_list.length === 0 && this.props.is_transaction_loading === false ?
										<h2 className="no-item-list">No items to display</h2> :
										<BootstrapTable ref='table' data={this.props.transaction_list} responsive table-bordered
											pagination={true}
											options={options}
											remote
											fetchInfo={{ dataTotalSize: this.props.total_count }}
											condensed
											trStyle={(row) => this.trStyleChange(row,this.props.sort_order)}
											keyField='id'>
											<TableHeaderColumn width="" thStyle={{ textAlign: 'center', width: '55px' }} tdStyle={{ ...td_style, textAlign: 'center', width: '55px' }} align="center" dataField='is_star' dataFormat={(cell, row) => this.dataFormatStar(cell, row)} >
												<SortingIcon onSortClick={this.onSortClick} field_name="Star" field_number="2" sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
											</TableHeaderColumn>
											<TableHeaderColumn width="" thStyle={{ textAlign: 'center', width: '100px' }} tdStyle={{ ...td_style, textAlign: 'center', width: '100px' }} dataField='date' dataFormat={this.displayDate}>
												<SortingIcon onSortClick={this.onSortClick} field_name="Date" field_number="3" sort_by={this.props.sort_by} sort_order={this.props.sort_order} />
											</TableHeaderColumn>
											<TableHeaderColumn width="" thStyle={{ width: '16%' }} tdStyle={{ ...td_style, width: '16%' }} dataField='financial_institute' dataFormat={(cell, row) => this.displayFinantial(cell, row)}>
												<SortingIcon onSortClick={this.onSortClick} field_name="Financial Institute" field_number="4" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
											<TableHeaderColumn width="" thStyle={{ width: '36%' }} tdStyle={{ ...td_style, width: '36%' }} dataField='narration' dataFormat={(cell, row) => this.displayNarration(cell)}>
												<SortingIcon onSortClick={this.onSortClick} field_name="Narration" field_number="5" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
											<TableHeaderColumn width="" thStyle={{ width: '15%', textAlign: 'right' }} tdStyle={{ ...td_style, width: '15%', textAlign: 'right' }} dataField='amount' dataFormat={(cell, row) => this.displayAmount(cell, row)} >
												<SortingIcon onSortClick={this.onSortClick} field_name="Amount" field_number="7" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
											<TableHeaderColumn width="" tdStyle={{ ...td_style, textAlign: 'center', width: '7%' }} thStyle={{ textAlign: 'center', width: '7%' }} dataField='type' dataFormat={(cell, row) => this.dataFormatType(cell, row)}>
												<SortingIcon onSortClick={this.onSortClick} field_name="CR/DR" field_number="6" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
											
											<TableHeaderColumn width="" columnClassName={'tim-0'} thStyle={{ textAlign: 'center', width: '75px' }} tdStyle={{ ...td_style, textAlign: 'center', width: '75px' }} dataField='status' dataFormat={(cell, row) => this.dataFormatStatus(cell, row)}>
												<SortingIcon onSortClick={this.onSortClick} field_name="Status" field_number="9" sort_by={this.props.sort_by} sort_order={this.props.sort_order} /></TableHeaderColumn>
											<TableHeaderColumn width="100px" tdStyle={td_style} dataField='' dataFormat={(cell, row) => this.dataFormatTask(cell, row)} dataSort></TableHeaderColumn>
										</BootstrapTable>
									}
								</div>
							</div>
						</div>
					</span>
					{/* End Table List */}
				</div>
				<ChangeLog
					show_log={this.state.show_log}
					toggleChangeLog={this.toggleChangeLog}
					logs={this.state.logs_list}
					main={true}
				/>
			</main>
		)
	}
}

function mapStateToProps(state) {
	const all_value = state.MoneyLink || {};
	const is_transaction_loading = all_value.is_transaction_loading;
	//const filter_by = all_value.filter_by
	const session_value = state.Session || {};
	const features_permissions_list = session_value.features_permissions_list || [];
	const cr_dr_type = all_value.cr_dr_type
	const bank_value = all_value.bank_value || null;
	return {
		is_transaction_loading, features_permissions_list, all_value, cr_dr_type, bank_value
	};
}

export default connect(mapStateToProps)(withRouter(MoneyLinkList));