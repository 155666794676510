import React from 'react'
import { Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withRouter,Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import BreadCrum from '../../Components/BoilerPlate/BreadCrum';
import { showAmount } from '../../Helpers/Currency';
import { Button, Dropdown } from 'react-bootstrap';
import SnapshotService from '../../Services/Snapshot/SnapshotService';
import Select from "react-select";
import RouteLeavingGuard from '../../Utils/RouteLeavingGuard';
import loadrew from '../../Assets/img/buktec-preloader.gif';
import ProgressBar from '../../Components/BoilerPlate/ProgressBar';
import { changeDateFormate } from '../../Helpers/DateFormat';
import { sendLog } from '../../Helpers/CreateLogs';
import { setBulkSnapshotList } from '../../Redux/Actions/Snapshot';
import Gstr2bRouteLeavingGuard from '../../Utils/Gstr2bRouteLeavingGuard';
import ReactSelect from 'react-select';



class GstrSnapshotView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			
			merchant_option: {},
			categories: [],
			merchants: [],
			all_merchant_list: [],
			all_old_merchant_list: [],
			snapshot_types: [],
			tags: [],
			isSelectFile:false,
			snapshot_list:[],
			is_dirty:false,
			next_route:false,
			closed:false,
			save_and_redirect:false,
			uploading : false,
			uploading_finished: false,
			snapshots : [],
			interval_id:"",
			entry_disbaled : false,
			scrollStep: 100, // Adjust this value as needed
			scrollTop: 0,
			scrollLeft: 0,
		}

		this.scrollRef = React.createRef();

		
	}


	// shortcut key scroll function
	scroll(direction) {
		const { scrollStep, scrollTop, scrollLeft } = this.state;
		const element = this.scrollRef.current;
	
		let newScrollTop = scrollTop;
		let newScrollLeft = scrollLeft;
			if (direction === "up") {
				newScrollTop = Math.max(scrollTop - scrollStep, 0);
			} else if (direction === "down") {
				newScrollTop = scrollTop + scrollStep;
			} else if (direction === "left") {
				newScrollLeft = Math.max(scrollLeft - scrollStep, 0);
			} else if (direction === "right") {
				newScrollLeft = scrollLeft + scrollStep;
			}
	
				// Update state and scroll position
			this.setState(
				{ scrollTop: newScrollTop, scrollLeft: newScrollLeft },
				() => {
					if (element) {
					element.scrollTop = newScrollTop;
					element.scrollLeft = newScrollLeft;
					}
				}
			);
	}
    customStyles = {
        control: (provided) => ({
          ...provided,
          minWidth: 200,
        }),
      };
	  closeAction = () => {
		this.setState({ closed: false })
	}
	saveBeforeLeave = (nextRoute) => {
		this.setState({ save_and_redirect: true, next_route: nextRoute }, function () {
		});
	}

	  componentDidMount = async () =>{
		if(this.props.bulk_snapshot_list.length == 0){
			// this.props.handleQuickEntryTabChange(1);
			this.props.history.push('/snapshot/gstr2b');
		}else{
			this.setState({isSelectFile:true})
			await this.getSnapshotMasters();
		}
	}

		 getSnapshotMasters = () => {
			SnapshotService.getSnapshotMasters().then((data) => {
			  if (data.response_code == 200) {
				let merchants = data.result.merchants.map((supp) => {
				  return {
					value: supp.id,
					label: supp.name,
					gst_no: supp.gst_no || "",
					pan_number: supp.pan_number || "",
					tds_percentage: supp.tds_percentage,
					snapshot_type: supp.snapshot_type,
					is_gst_claim_eligible: supp.is_gst_claim_eligible,
					is_msme: supp.is_msme,
					is_perquisite: supp.is_perquisite,
					type: supp.type,
					category_id: supp.category_id,
					tags: supp.tags || [],
					party_ledger_guid: supp.party_ledger_guid || "",
					purchase_ledger_guid: supp.purchase_ledger_guid || "",
					sales_ledger_guid: supp.sales_ledger_guid || "",
				  };
				});
		
				let tagData = data.result.tags.map((tag) => ({
				  value: tag.id,
				  label: tag.name,
				}));
				console.log("PArty Details List ----", data.result);
				this.setState({
				  // payment_details: data.result.financial_institutes,
				  categories: data.result.categories,
				  tags: tagData,
				  all_merchant_list: merchants,
				  all_old_merchant_list: _.cloneDeep(merchants),
				  snapshot_types: data.result.snapshot_types,
				  // line_items_list: data.result.line_items
				},()=>{
					if(this.props.bulk_snapshot_list.length > 0){
						this.prefillSnapshotData()
					}
					
				});
			  }
			});
		  };
	
	prefillSnapshotData = () => {
		let snapshot_list = [...this.props.bulk_snapshot_list]
		let arr = [];
		console.log("snapshot list",snapshot_list)
		const newData = snapshot_list.map((item,index)=>{
			var record = this.findPartyDetails(item)
			console.log(
				"Sub Details row Value --- ",
				
				"merchant --- ",
				record
			  );

			  const prefillTags = record.tags ? record.tags.map((tag) => { const foundTag = 
				this.state.tags.find( (t) => t.value === tag.id); return foundTag ? foundTag.value : tag.id;})
			  : [];
			  
			  const isperquisite = record.is_perquisite ? record.is_perquisite : false;
			  const isMsme = record.is_msme ? record.is_msme : false;
			  const isGstClaimElligible = false;
			  const category = record.category_id ? record.category_id : "";
			  //find snapshot type
			  let record_type = 2;
			  if(record.type == 2 || record.type == 6){
				record_type = record.type
			  }
			//   const snapshot_type_expense = this.state.snapshot_types.find((types) => (types.id == record_type) && types.expense == 1);
			//   console.log("snapshot type expense",snapshot_type_expense)
			//   let type = 2;
			//   if(snapshot_type_expense != undefined){
			// 	type = snapshot_type_expense.id
			//   }else{
			// 	type = 2
			//   }
			  const type = record_type;
			  const snapfound = this.state.snapshot_types.find((types) => types.id == type)
			  const expense = snapfound.expense  ? snapfound.expense : 0;



	
			  console.log("prefill",prefillTags,isperquisite,category,type,expense)
			  var rowData = {
				category_id : category ,
				additional_information:"",
				is_new:
				record.value == 0 && record.label != "" ? true : false,
				merchant_id: record.value,
				new_value: record.label,
				merchant_name: record.label,
				merchant_option: record,
				merchant_type: record.type ? record.type : 2,
				gst_no: record.gst_no.toUpperCase(),
                pan_number: record.pan_number.toUpperCase(),
				tags:prefillTags,
                is_msme: isMsme,
                is_dirty: false,
				is_perquisite: isperquisite,
                expense: expense,//snapId ? snapId.expense : 0,
                type: type,
				inventory_mode: false,
				gst_override:false,
				hide_gst_override: true,
				date: item.invoice_date,
				invoice_number: item.invoice_number,
				cgst_amount: item.cgst ? item.cgst : "",
				sgst_amount: item.sgst ?  item.sgst : "",
				igst_amount: item.igst ? item.igst : "",
				gst_total_amount: "",
				is_igst: item.igst ? true : false,
				total_amount : item.total_value ? item.total_value : "",
				tds_percentage : record.tds_percentage ? record.tds_percentage : "",
				taxable_value : item.taxable_value ? item.taxable_value : "",
				tds_amount: "",
				gst_status : true,
				message: 'Waiting...',
                status: 'accepted',
				uploading : false
			  }

			  rowData.is_perquisite = (rowData.type == 11 || rowData.type == 7 || rowData.type == 10 || rowData.type == 1 || rowData.type == 9) ? false : true;
			  rowData.tds_percentage = (rowData.type == 9 || rowData.type == 10 || rowData.type == 4 || rowData.type == 5) ? "" : rowData.tds_percentage;


			  let gst_total_amount = parseFloat(rowData.sgst_amount || 0) +  parseFloat(rowData.cgst_amount || 0) + parseFloat(rowData.igst_amount || 0);
			  rowData.gst_total_amount = gst_total_amount;
			  let total_amt = parseFloat(rowData.total_amount || 0) - parseFloat(rowData.gst_total_amount || 0);
			let tds_amount =
			  (total_amt * parseFloat(rowData.tds_percentage || 0)) / 100;
			if(tds_amount < 0) tds_amount = 0;
			rowData.tds_amount = tds_amount


			  console.log("rowdata",rowData)
			  arr.push(rowData)


			
		})

		console.log("arrr",arr)
		this.setState({snapshot_list:arr,isSelectFile:false,})
	}
	findPartyDetails = (row) => {
		console.log("row ------------", row);
		var partyName = row.name_of_party;
		var gstNo = row.gstin_uin_of_party ? row.gstin_uin_of_party.toString() : "";
		var panNo = row.pan_number ? row.pan_number.toString() : "";
		var defaultData = {
		  category_id: "",
		  gst_no: row.gstin_uin_of_party ? row.gstin_uin_of_party.toString() : "",
		  value: 0,
		  label: row.name_of_party ? row.name_of_party.toString() : "",
		  pan_number: row.pan_number ? row.pan_number.toString() : "",
		  tds_percentage: "",
		  snapshot_type: false,
		  is_msme: false,
		  type: "",
		  tags: [],
		  party_ledger_guid: "",
		  purchase_ledger_guid: "",
		  sales_ledger_guid: "",
		};
		var record = defaultData;
		if (gstNo) {
		  console.log("Compare with GST First");
		  record = this.state.all_merchant_list.find(
			(record) => record.gst_no.toUpperCase() === gstNo.toUpperCase()
		  );
		}
		if (record && record.value == 0 && panNo) {
		  console.log("Compare with Pan number secondly");
		  record = this.state.all_merchant_list.find(
			(record) => record.pan_number.toUpperCase() === panNo.toUpperCase()
		  );
		}
		if (record && record.value == 0 && partyName) {
		  console.log("Compare with party name at last");
		  record = this.state.all_merchant_list.find(
			(record) => record.label.toUpperCase() === partyName.toUpperCase()
		  );
		}
		if (!record) record = defaultData;
		console.log("record data ---------- ", record);
		return record;
	  };
	
	closeTransactionLinking = () =>{
		this.props.history.goBack();
	   //  this.props.dispatch(setBulkSnapshotList([]))
   	}
	   payloadformatDateNew = (dateString) => {
		const parts = dateString.split("-");
		// Reformat the date to "yyyy-mm-dd"
		return `${parts[2]}-${parts[1]}-${parts[0]}`;
	  };
	handleTypeChange = async(e,index)=>{
			console.log("e",e.target.value,index)
			const { name, value } = e.target;
			let snapshot_list = [...this.state.snapshot_list];

			if(name == 'type'){
				if(value !== ""){
					const snapData = this.state.snapshot_types.find(type => type.id == value);
					const oldValue = snapshot_list[index].type;
					snapshot_list[index].type = value;
					let expense = true;
					let isPerquisiteDissable = false;
					expense = snapData.expense == 1 ? true : false;
					let merchant_type = snapData.merchant_type;
					if (expense == false && this.state.is_perquisite == true) {
						if (
									await confirm({
										confirmation:
										"This action will uncheck the perquisite checkbox. Do you still want to continue?",
									})
									) {
										snapshot_list[index].expense = false;
										snapshot_list[index].is_perquisite = false
									}else{
										snapshot_list[index].type = oldValue;
										return
									}
						
					}

					snapshot_list[index].expense = expense;
					snapshot_list[index].merchant_type = merchant_type;

					let merchant_option = snapshot_list[index].merchant_option;
					if (Object.keys(merchant_option).length != 0) {
						let merchant_list = this.state.all_merchant_list;
						let merchant_data = merchant_list.find(
						(merchant) =>
							merchant.gst_no == snapshot_list[index].gst_no &&
							merchant.label == merchant_option.label
						);
						merchant_option.value = !merchant_data ? 0 : merchant_data.value;
						snapshot_list[index].merchant_option = merchant_option;
					}
					if(merchant_type == 2){
						snapshot_list[index].is_customer = false
					}else{
						snapshot_list[index].is_supplier = false
					}

				}
				this.setState({snapshot_list,is_dirty:true})

		}

	}

	createSnapshot = async() =>{
		let snapshot_list = [...this.state.snapshot_list];
		this.setState({ uploading_file_percent: 50, isSelectFile:true});
		for(let i = 0 ; i < snapshot_list.length ; ++i){
			snapshot_list[i].uploading = true;
			// finding snapshot and expense and merchnat type form snapshot_types list //
			let type = 8;
			let expense = 1;
			if(snapshot_list[i].type){
				type = snapshot_list[i].type;
				expense = snapshot_list[i].expense;
			}

			let snapshot_type = this.state.snapshot_types.find(types => types.id == type);
			let merchant_type = 2;

			if (snapshot_type) {
				merchant_type = snapshot_type.merchant_type;
				expense = snapshot_type.expense;
			}
			// ***********************end******************************************************** //
			let additional_information = "";
			let merchant_flag = 0;
			if (snapshot_list[i].merchant_id == 0 && snapshot_list[i].merchant_name != null) {
				merchant_flag = 1;
			}
			// if (snapshot_list[i].merchant_id == 0 && ((snapshot_list[i].merchant_name != null && !snapshot_list[i].pan_number) || (!snapshot_list[i].merchant_name && snapshot_list[i].pan_number))) {
			// 	additional_information = "Merchant Name: " + (snapshot_list[i].merchant_name || "") + ", GSTIN: " + (snapshot_list[i].gst_no || "") + ", PAN: " + (snapshot_list[i].pan_number || "");
			// }
			// let gst_status = snapshot_list[i].gst_status ? 1 : snapshot_list[i].gst_no ? 1 : 0;
			let gst_status;
			if(snapshot_list[i].gst_no && ((snapshot_list[i].sgst_amount && snapshot_list[i].cgst_amount) || snapshot_list[i].igst_amount)){
				gst_status = 1;
			}else{
				gst_status = 1;
			}
			console.log("gst status",gst_status)
			let gst_total_amount = (parseFloat(snapshot_list[i].sgst_amount || 0) + parseFloat(snapshot_list[i].cgst_amount || 0) + parseFloat(snapshot_list[i].igst_amount || 0))
			let tds_status = 0;
			let tds_amount = null;
			let tds_percentage = null;
			
			if (!!snapshot_list[i].total_amount && !!snapshot_list[i].tds_percentage) {
				let total_amt = parseFloat(snapshot_list[i].total_amount || 0) - parseFloat(gst_total_amount || 0);
	
				tds_amount = (total_amt * parseFloat(snapshot_list[i].tds_percentage || 0) / 100);
	
				if (tds_amount < 0) tds_amount = 0;
				tds_status = 1
			} else {
				tds_status = 0
			}
			
			// if(!!snapshot_list[i].total_amount && !!snapshot_list[i].taxable_value){
			// 	let total_amount_after_gst = parseFloat(snapshot_list[i].total_amount) - parseFloat(gst_total_amount)
				
				
			// 	if(total_amount_after_gst != snapshot_list[i].taxable_value){
			// 		tds_amount = parseFloat(total_amount_after_gst) - parseFloat(snapshot_list[i].taxable_value);
			// 		console.log("tds",total_amount_after_gst,tds_amount)
			// 		tds_amount = tds_amount.toFixed(2);
			// 		tds_percentage = ((tds_amount*100)/parseFloat(total_amount_after_gst))

			// 		// tds_amount = parseFloat(snapshot_list[i].total_amount)- parseFloat(gst_total_amount) - parseFloat(snapshot_list[i].taxable_value);
			// 		// console.log("tds amount",tds_amount,snapshot_list[i].total_amount,snapshot_list[i].taxable_value)
			// 		// tds_amount = tds_amount.toFixed(2);
			// 		// if(tds_amount < 0) tds_amount = 0;
			// 		// let total_amt = parseFloat(snapshot_list[i].total_amount || 0) - parseFloat(gst_total_amount || 0);
	
			// 		// tds_percentage = ((tds_amount * 100)/parseFloat(total_amt))

			// 		// console.log("tds per",tds_percentage)

			// 	}
			// }

			let data = {
				"expense": expense,
				"type": type,
           		"merchant_type": merchant_type,
				"date" : snapshot_list[i].date ? this.payloadformatDateNew(snapshot_list[i].date) : changeDateFormate(new Date()),
				"merchant_name": snapshot_list[i].merchant_name || null,
				"merchant_update": merchant_flag,
				"merchant_id": snapshot_list[i].merchant_id || 0,
				"gst_no": snapshot_list[i].gst_no || "",
				"invoice_number": snapshot_list[i].invoice_number || "",
				"total_amount": parseFloat(snapshot_list[i].total_amount) || null,
				"category_id": snapshot_list[i].category_id || null,
				"tags": snapshot_list[i].tags,
				"additional_information": additional_information,
				"payment_status": 2,
				"payment_total": 0,
				"payment_information": [],
				"gst_status": gst_status,
				"sgst_amount": gst_status ? snapshot_list[i].sgst_amount ? parseFloat(snapshot_list[i].sgst_amount): null : null,
				"cgst_amount": gst_status ? snapshot_list[i].cgst_amount ?  parseFloat(snapshot_list[i].cgst_amount) : null : null,
				"igst_amount": gst_status ? snapshot_list[i].igst_amount ? parseFloat(snapshot_list[i].igst_amount) : null : null,
				"gst_total_amount": gst_status ? gst_total_amount ? parseFloat(gst_total_amount) : null : null,
				"tds_status": tds_status ? 1 : 0,
				"tds_details":tds_status ? [{
					base_amount:(parseFloat(snapshot_list[i].total_amount) || 0) - (snapshot_list[i].gst_status ? parseFloat(gst_total_amount) : 0) ,
					tds_amount:tds_status == 1 ? parseFloat(tds_amount) : 0,
					tds_percentage: tds_status == 1 ? parseFloat(snapshot_list[i].tds_percentage) : 0,
					}] : [],
				"tds_total_amount": tds_status == 1 ? parseFloat(tds_amount) : null,
				"pan_number": snapshot_list[i].pan_number ? snapshot_list[i].pan_number : "",
				"mode": 1,
            	"file_url": "",
				"approval_comment": null,
				"approval_status": null,
				"approved_amount": null,
				"new_tags": [],
				"voucher_status": 0,
            	"is_duplicate": 0,
				"prediction_id": null,
				"vouchers": [],
            	"source": 5, //need to find source
				"is_msme": snapshot_list[i].is_msme ? 1 : 0,
            	"is_perquisite": snapshot_list[i].is_perquisite ? 1 : 0,
				"is_gst_claim_eligible" : gst_status ? 1 : 0,
				"snapshot_type_update": 0,
				"synced_by_user": null,
				"entry_status": 1,
				"accounting_status": 3,
				"is_entity_mismatch":  0,
				"inventory_mode": 0,
            	"inventory_details": null,
				"has_igst": snapshot_list[i].igst_amount ? 1 : 0,
				"gst_summary": null,
				"hsn_sac_summary": null,
				"is_reimbursement": 0,
				"is_same_invoice": 0,
				"reimbursement_narration": null,
				"request_id": null,
				"is_gst_override":0,
				"is_star":0,
            	"amount_round_off": 0,
				"gst_no_prediction_status": 0,
				"total_amount_prediction_status": 0,
				"igst_amount_prediction_status": 0,
				"sgst_amount_prediction_status": 0,
				"cgst_amount_prediction_status": 0,
				"date_prediction_status": 0,
				"merchant_name_prediction_status": 0,
				"snapshot_number_prediction_status": 0,
				"merchant_id_prediction_status": 0,
				"pan_number_prediction_status": 0,
				"snapshot_type_prediction_status": 0,
				"expense_prediction_status": 0,
				"category_id_prediction_status": 0,
				"tds_percentage_prediction_status": 0,
				"tags_prediction_status": 0,
				"is_msme_prediction_status": 0,
				"is_entity_mismatch_prediction_status": 0,
			}

			// if (snapshot_list[i].gst_no) {
			// 	let customer_gst = snapshot_list[i].gst_no ? snapshot_list[i].gst_no.slice(0, 2) : null || null;
			// 	let entity_gst = (!!this.props.entity_gst_no) ? this.props.entity_gst_no.slice(0, 2) : null;
	
			// 	if (customer_gst == entity_gst && customer_gst && entity_gst) {
			// 		data.has_igst = 0;
			// 		data.igst_amount = null;
			// 	} else if (!customer_gst || !entity_gst) {
			// 		data.has_igst = 1;
			// 		data.igst_amount = null;
			// 	} else if (customer_gst != entity_gst && customer_gst && entity_gst) {
			// 		data.has_igst = 1;
			// 		data.sgst_amount = null;
			// 		data.cgst_amount = null;
			// 	} else {
			// 		data.has_igst = 0;
			// 		data.igst_amount = null;
			// 	}
			// }

			console.log("data",data)
			SnapshotService.createSnapshot(data).then(async (resp_data) => {
				let message = "";
				if (resp_data.response_code == 200) {
					 //add progress value
					//  this.setState({ uploading_file_percent: 100, file_uploaded_count: (this.state.file_uploaded_count + 1) });
					this.setState({ uploading_file_percent: 100, });
					message = "Uploaded";
					  //add log entry
					sendLog({ module_type: 1, module_id: resp_data.result.id, activity: ['Snapshot created using gstr2b '] });

					let snapshot = {
						attachment_status: data.file_url,
						category: null,
						date: data.date,
						expense: data.expense,
						gst_status: data.gst_status,
						id: resp_data.result.id,
						is_star: 0,
						message_status: 0,
						name: data.merchant_name,
						payment_information: [], // want from api
						payment_status: data.payment_status,
						snapshot_no: resp_data.result.snapshot_no,
						status: data.snapshot_status,
						tags: [],
						task_status: 0,
						tds_status: data.tds_status,
						total_amount: data.total_amount ? data.total_amount : "",
						type: parseInt(data.type),
						is_duplicate: resp_data.result.is_duplicate,
					}
					this.setState(prevState => ({
						snapshots: [...prevState.snapshots, snapshot]
					}))
				}else if (resp_data.response_code == 400) {
                if (resp_data.reason.is_duplicate) {
                    message = "Duplicate Snapshot";
                    snapshot_list[i].status = 'rejected';
                } else if (resp_data.reason.is_same_invoice) {
                    message = "Is Same Invoice";
                    snapshot_list[i].status = 'rejected';
                } else {
                    message = "Error 104 : Could not create snapshot";
                    snapshot_list[i].status = 'rejected';
                }
            	}else if (data == 'timeout') {
					message = "Could not connect to server";
					snapshot_list[i].status = 'rejected';
            	}else {
					message = "Could not connect to server";
					snapshot_list[i].status = 'rejected';
            	}
				snapshot_list[i].message = message;
				snapshot_list[i].uploading = false;
				setTimeout(() => {
					this.setState({ uploading_file_percent: 0 });
				}, 1000);
			})
		}
		await this.waitforme(3000);
		this.setState({snapshot_list,is_dirty:false,isSelectFile:false})
	}
	saveEntries = async() =>{
		this.setState({ uploading: true,entry_disbaled:true,uploading_file_percent:10 });
			
		await this.createSnapshot();

		let intervalId = setInterval(() => {
            this.setState({uploading_finished: true,uploading: false,entry_disbaled:false})
        }, 2000);

        this.setState({ interval_id: intervalId })
		
		
		// this.props.history.push('/snapshot/gstr2b');
	}

	finishUpload = () =>{
		this.setState({snapshot_list:[]})
		this.props.dispatch(setBulkSnapshotList([]));
		this.props.history.push('/snapshot/evidence');
	}
	waitforme(milisec) {
		return new Promise(resolve => {
			setTimeout(() => { resolve('') }, milisec);
		})
	}
	render() {
		console.log("snapshot service",this.state.snapshot_list)
		return (
			<main className="content-wrapper-new">
			<Gstr2bRouteLeavingGuard
			when={this.state.is_dirty}
			saveChanges={this.saveBeforeLeave}
			closed={this.state.closed}
			closeAction={this.closeAction}
			/>
				<div className="container-fluid container-padding-new">
					
					{/* Breadcrumb */}
					<div className="row">
						<div className="col-12">
							<div className="main-sub-header-new">
								<div className="breadcrumb-header-new">
									<div className="row">
										<div className="col-12">
											<BreadCrum level={2} />
										</div>
									</div>
									<div className="row">
										<div className="col-3">
											<h2 className="breadcrumb-title-new">
												Snapshot
												{/* <span className="help-icon" > */}
												{/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Help</Tooltip>}><span className="icon-Information help-icon" onClick={this.toggleSteps}></span></OverlayTrigger> */}
												{/* </span> */}
											</h2>
										</div>
										<div className="col-9 d-flex align-items-center justify-content-end gap-10">
										{(this.state.duplicate_cgst || this.state.duplicate_sgst || this.state.duplicate_igst)&&<span className='error_right mb-2'>Duplicate ledger(s) found. Please check snapshot entries and GST Ledgers.</span>}
											<h2 className="breadcrumb-title-new sub-header-cicon">
												{/* <a href=""> */}
												{/* <span class="icon-Cross-with-grey-circle "><span class="path1"></span><span class="path2"></span><span class="path3"></span></span> */}
												{/* </a> */}
												{/* <Link onClick={() => this.closeTransactionLinking()}>
													<span class="icon-Cross-with-grey-circle "><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
												</Link> */}
												<Button variant="outline-success reset_btn_new" onClick={() => this.closeTransactionLinking()}>Back</Button>
											</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* End Breadcrumb */}

					 {/* Loader */}
						{this.state.isSelectFile && (
							<>
								<div className="ie_loader_overlay"></div>
								<img className="ie_loader_img" src={loadrew} />
							</>
						)}
      				  {/* End Loader */}

					{/* Table Design  */}
					{/* <div className="form-content-sec-new mt-0"> */}
						<div className="form-sec-inner-new">
							<div className="row">
								<div className="col-sm-12">
									{/* class import-excel */}
									<div className="import-excel-popup">
										<div className="import-excel-border my-0">
											<div className="import-excel-table" ref={this.scrollRef}>
												<table className="table snap-excel-table">
													{/* <colgroup>
														<col></col>
														<col></col>
														<col></col>
														<col style={{ minWidth: '250px' }}></col>
														<col style={{ minWidth: '150px' }}></col>
														<col style={{ minWidth: '200px' }}></col>
														<col style={{ minWidth: '200px' }}></col>
														<col style={{ minWidth: '120px' }}></col>
														<col style={{ minWidth: '100px' }}></col>
														<col style={{ minWidth: '100px' }}></col>
														<col style={{ minWidth: '100px' }}></col>
														<col style={{ minWidth: '120px' }}></col>
													</colgroup> */}
													<colgroup>
													<col></col>
													<col></col>
													<col style={{ minWidth: '150px', maxWidth: '150px' }}></col>
													{/* <col style={{ minWidth: '250px', maxWidth: '250px' }}></col> */}
                                                    <col style={{minWidth: '150px',maxWidth:'150px'}}></col>
                                                    <col style={{minWidth: '150px',maxWidth:'150px'}}></col>
													{/* <col className="td_set_party_gstsie"></col> */}
													<col className="td_set_selectie"></col>
													<col className="td_set_selectie"></col>
                                                    <col className="td_set_selectie"></col>
                                                    <col className="td_set_selectie"></col>
                                                    <col className="td_set_selectie"></col>
													<col className="td_set_amountie"></col>
													<col className="td_set_amountie"></col>
                                                    <col className="td_set_amountie"></col>
                                                    <col className="td_set_amountie"></col>
													<col style={{ minWidth: '300px', maxWidth: '300px' }}></col>
													
													
													
												</colgroup>

												<thead>
													<tr>
														<th className="text-center">Ref. Date</th>
														<th>Ref. No.</th>
														<th>Type</th>
														<th style={{ minWidth: '250px', maxWidth: '250px' }}>Party Name</th>
														<th>Party GSTIN</th>
                                                        <th>Party PAN</th>
														<th>Category</th>
                                                        <th>Tags</th>
														<th className="text-right">

															SGST
														</th>
														<th className="text-right">

															CGST
														</th>
														<th className="text-right">

															IGST
														</th>
														<th className="text-right">Total Amount</th>
                                                        <th className="text-center">Msme</th>
                                                        <th className="text-center">Perquisite</th>
														<th style={{ minWidth: '300px', maxWidth: '300px' }}>Status</th>
														
													</tr>
												</thead>

													<tbody>
														
															{this.state.snapshot_list.map((value,index)=>{
																return(
																	<tr key={index}>
																	<td className="ie_td_disabled text-center">{this.state.snapshot_list[index].date}</td>
																	<td className="ie_td_disabled">
																		<div className="ellipsisc">
																			<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.state.snapshot_list[index].invoice_number}</Tooltip>}>
																				<span>{this.state.snapshot_list[index].invoice_number}</span>
																			</OverlayTrigger>
																		</div>
																	</td>
																	<td className='p-0'>
																	{/* <select
																		// disabled={
																		// row.disabled || this.state.isDownload
																		// }
																		className="ie_form ie_select_box"
																		value={this.state.snapshot_list[index].type}
																		// value={""}
																		onChange={(e) => {
																		this.handleTypeChange(e, index);
																		}}
																		// disabled={this.props.VIEW_ONLY_SNAPSHOT || !editable || this.props.is_reimbursement}
																		name="type"
																	>
																		<option value="">Select Type</option>
																		{this.state.snapshot_types.filter((type) => type.expense === 1 && (type.id == 6 || type.id == 2) ).map(
																		(type, index) => {
																			return (
																			<option key={index} value={type.id}>
																				{type.expense} {type.name}
																			</option>
																			);
																		}
																		)}
																	</select> */}
																	<Form>
																		{/* <ReactSelect
																		className="Select-Search typeed-dd"
																		classNamePrefix="Select type-select "
																		value = {this.state.snapshot_list[index].type}

																		/> */}

																<ReactSelect
                                                                    className="Select-Search typeed-dd"
																	classNamePrefix="Select type-select "
																	value={
																		this.state.snapshot_list[index].type
																		  ? {
																			  value: this.state.snapshot_list[index].type,
																			  label: this.state.snapshot_types.find(
																				(type) => type.id === this.state.snapshot_list[index].type
																			  )?.name,
																			}
																		  : null
																	  }
																	options={this.state.snapshot_types
																		.filter((type) => type.expense === 1 && (type.id === 6 || type.id === 2))
																		.map((type) => ({
																		  value: type.id,
																		  label: `${type.expense} ${type.name}`,
																		}))}
																	isRtl={false}
																	isSearchable={true}
																	onChange={(selectedOption) => {
																		this.handleTypeChange(
																		  { target: { name: "type", value: selectedOption?.value } },
																		  index
																		);
																	  }}
																	name="type"
                                                                    placeholder="Select Type"
                                                                    isClearable={false}
																	menuPlacement="auto"
                                                                    />
																	</Form>
																	</td>
																	<td className="ie_td_disabled" style={{ minWidth: '250px', maxWidth: '250px' }}>
																		<div className="ellipsisc">
																			<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.state.snapshot_list[index].merchant_name}</Tooltip>}>
																				<span>{this.state.snapshot_list[index].merchant_name}</span>
																			</OverlayTrigger>
																		</div>
																	</td>
																	<td className="ie_td_disabled">{this.state.snapshot_list[index].gst_no}</td>
																	<td className="ie_td_disabled">{this.state.snapshot_list[index].pan_number}</td>

																	<td className="ie_td_disabled">
																		{this.state.snapshot_list[index].category_id && 	<select
																			// disabled={
																			// 	row.disabled || this.state.isDownload
																			// }
																			disabled={true}
																			className="ie_form ie_select_box"
																			value={this.state.snapshot_list[index].category_id}
																			// onChange={(e) =>
																			// 	this.handleCategoryChange(e, rowIndex)
																			// }
																			name="category_id"
																			>
																			{/* {!row.category_id && ( */}
																			<option value="" disabled hidden>
																				Select Categories
																			</option>
																			{/* )} */}
																			{/* <option value="-2" className="ie_add_new">
																				Add New Category
																			</option> */}
																			{this.state.categories.map(
																				(category, index) => {
																				return (
																					<option
																					key={index}
																					value={category.id}
																					>
																					{category.name}
																					</option>
																				);
																				}
																			)}
																		</select>}
																
																	</td>
																	<td className='ie_td_disabled '>
																		{
																			this.state.snapshot_list[index].tags.length > 0 &&
																			<Select
																	// isDisabled={
																	// 	row.disabled || this.state.isDownload
																	// }
																	isDisabled = {true}
																	className="ie_form_multi"
																	isMulti
																	options={this.state.tags}
																	value={(this.state.snapshot_list[index].tags || []).map((tag) =>
																		this.state.tags.find(
																		(option) => option.value === tag
																		)
																	)}
																	// onChange={(selectedOptions) =>
																	// 	this.handleMultiSelectChange(
																	// 	selectedOptions,
																	// 	rowIndex
																	// 	)
																	// }
																	// onInputChange={(selectedOptions) =>
																	// 	this.handleChangeAddNew(
																	// 	selectedOptions,
																	// 	rowIndex
																	// 	)
																	// }
																	// onKeyDown={(e) =>
																	// 	this.handleKeyDown(e, rowIndex)
																	// }
																	styles={this.customStyles}
																	/>
																		}
																	
																	</td>
																	
																	<td className="ie_td_disabled text-right">{ this.state.snapshot_list[index].sgst_amount ? `₹ ${this.state.snapshot_list[index].sgst_amount}` : ""}</td>
																	<td className="ie_td_disabled text-right">{ this.state.snapshot_list[index].cgst_amount ? `₹ ${this.state.snapshot_list[index].cgst_amount}` : ""}</td>
																	<td className="ie_td_disabled text-right">{ this.state.snapshot_list[index].igst_amount ? `₹ ${this.state.snapshot_list[index].igst_amount}` : ""}</td>
																	<td className="ie_td_disabled text-right">{ this.state.snapshot_list[index].total_amount ? `₹ ${this.state.snapshot_list[index].total_amount}` : ""}</td>

																	<td className='ie_td_disabled '>
																	<div className="sup-cust-check lable-input justify-content-end">
																	<input
																		// disabled={
																		// row.disabled || this.state.isDownload
																		// }
																		disabled={true}
																		className="styled-checkbox"
																		id="styled-checkbox-msme"
																		type="checkbox"
																		name="is_msme"
																		checked={this.state.snapshot_list[index].is_msme}
																		// onChange={(e) => {
																		// this.handleChange(e, rowIndex);
																		// }}

																				/>
																				</div>
																	</td>
																	<td className='ie_td_disabled'>
																	<div className="sup-cust-check">
																		<input
																			// disabled={
																			// row.disabled || this.state.isDownload || (row.type == 11 || row.type == 7 || row.type == 10 || row.type == 1 || row.type == 9)
																			// }
																			disabled={true}
																			className="styled-checkbox"
																			type="checkbox"
																			// disabled={!!!row.expense}
																			// onChange={(e) => {
																			// this.handleChange(e, rowIndex);
																			// }}
																			name="is_perquisite"
																			checked={this.state.snapshot_list[index].is_perquisite} // assuming cell contains the boolean value
																		// onChange={(e) =>
																		//   this.handlePerquisitCheckboxChange(
																		//     e,
																		//     rowIndex
																		//   )
																		// }
																		/>
																		</div>
																	</td>
																	{this.state.snapshot_list[index].uploading ?
                                                                    <td className="va-middle">
                                                                        <ProgressBar key={index} bgcolor={'#77d7b1'} completed={this.state.uploading_file_percent} /></td>
                                                                    :
                                                                    <td><span className={this.state.snapshot_list[index].status == 'rejected' ? "red-color" : ""}>{this.state.snapshot_list[index].message}</span></td>
                                                                }
																
																	
																</tr>
															)})}
														
														
													</tbody>
												</table>
											</div>
										</div>
									</div>
									{/* end class import-excel */}
								</div>
							</div>

							<div className="row mt-2">
								<div className="col-sm-6 ie_arrows">
									{/* Arrows */}
									{/* <div className="ie_arrows"> */}
										<span className="icon-right-arrow ie_arrows_icon ie_left"  onClick={() => this.scroll("left")}></span>
										<span className="icon-right-arrow-1 ie_arrows_icon ie_right"  onClick={() => this.scroll("right")}></span>
										<span className="icon-right-arrow-1 ie_arrows_icon pt-1" onClick={() => this.scroll("down")}></span>
										<span className="icon-right-arrow ie_arrows_icon"  onClick={() => this.scroll("up")}></span>
									{/* </div> */}
									{/* End Arrows */}
								</div>
								<div className="col-sm-6">
									<div className="reset_apply_btn_new">
										{ (!this.state.uploading_finished) &&
											<Button variant="success apply_btn_new" onClick={this.saveEntries} disabled={this.state.entry_disbaled}>Create Entries</Button>
										}
										{
											this.state.uploading_finished && 
											<Button variant="success apply_btn_new" onClick={this.finishUpload}>Finish</Button>
										}
										
									</div>
								</div>
							</div>
						</div>
					{/* </div> */}
					{/* End Table Design  */}


					{/* Model Popup */}
					
					{/*End  Model Popup */}

				</div>
			</main >
		)
	}
}
function mapStateToProps(state) {
	const all_value = state.Snapshot || {};
	const session_values = state.Session || {};
	const bulk_snapshot_list = all_value.bulk_snapshot_list || [];
	const cgst_ledger_guid = session_values.entity_config_param.cgst_ledger_guid
	const sgst_ledger_guid = session_values.entity_config_param.sgst_ledger_guid
	const igst_ledger_guid = session_values.entity_config_param.igst_ledger_guid
	const is_gst_ledger =  session_values.entity_config_param.is_gst_ledger

	return {
		 all_value, bulk_snapshot_list,session_values,cgst_ledger_guid,sgst_ledger_guid,igst_ledger_guid,is_gst_ledger
		};
}
export default connect(mapStateToProps)(withRouter(GstrSnapshotView))