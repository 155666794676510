import { 
    SET_SNAPSHOT_FILTER_DATA,SNAPSHOT_WIDGET_DATA,SET_PAGE_NO,
    SET_PER_PAGE_ITEM,  SET_IS_SNAPSHOT_LOADING, SET_SNAPSHOT_SEARCHING_TEXT, 
    SMS_SET_PAGE_NO, SMS_SET_PER_PAGE_ITEM, SMS_SET_TOTAL_COUNT, SMS_SET_PAYMENT_INFORMATION, 
    SET_FINANCIAL_YEAR_FILTER_NAME, SET_SNAPSHOT_CARD_FILTER, SET_COMMUNICATION_BAR_DIRTY_STATUS,
    SET_SNAP_TEMP_VOUCHER_LIST, SET_SNAPSHOT_DEFAULT_WIDGET_CARDS, SET_SNAPSHOT_FILTER_DATE_RANGE, SET_TEMP_INVENTORY_DATA,  
    SET_BULK_SNAPSHOT_LIST,
    SET_GSTR2B_DATE} from "./Types";

export const setSnapshotFilterData = (filter_data) => ({
  type: SET_SNAPSHOT_FILTER_DATA,
  payload: filter_data,
});
  
export const setSnapshotWidgets = (widget_count) => ({
  type: SNAPSHOT_WIDGET_DATA,
  payload: widget_count,
});
  
export const setPageNo = (page_no) => ({
  type: SET_PAGE_NO,
  payload: page_no,
});
  
export const setPagePerItem = (per_page_item) => ({
  type: SET_PER_PAGE_ITEM,
  payload: per_page_item,
});
  
export const setSnapshotLoadingStatus = (is_snapshot_loading) => ({
  type: SET_IS_SNAPSHOT_LOADING,
  payload: is_snapshot_loading,
});

// export const setSmsForEvidence = (sms) => ({
//   type: SET_SMS_FOR_EVIDENCE,
//   payload: sms,
// });

export const smsSetPageNo = (page_no) => ({
  type: SMS_SET_PAGE_NO,
  payload: page_no,
});

export const smsSetPagePerItem = (per_page_item) => ({
  type: SMS_SET_PER_PAGE_ITEM,
  payload: per_page_item,
});

export const smsSetTotalCount = (total_count) => ({
  type: SMS_SET_TOTAL_COUNT,
  payload: total_count,
});

export const setPaymentInformation = (payment_info) => ({
  type: SMS_SET_PAYMENT_INFORMATION,
  payload: payment_info,
});

export const setSnapshotFinancialYearname = (name) => ({
  type: SET_FINANCIAL_YEAR_FILTER_NAME,
  payload: name,
});

export const setSnapshotCardFilter = (name) => ({
  type: SET_SNAPSHOT_CARD_FILTER,
  payload: name,
});

export const setSnapshotSearchText = (search_text) => ({
  type: SET_SNAPSHOT_SEARCHING_TEXT,
  payload: search_text,
});

export const setCommunicationBarDirtyStatus = (communication_bar_dirty_state) => ({
  type: SET_COMMUNICATION_BAR_DIRTY_STATUS,
  payload: communication_bar_dirty_state,
});

export const setTempVoucherList = (list) => ({
  type: SET_SNAP_TEMP_VOUCHER_LIST,
  payload: list,
});

export const setSnapshotDefaultWidgets = (default_cards) => ({
  type: SET_SNAPSHOT_DEFAULT_WIDGET_CARDS,
  payload: default_cards,
});

export const setFilterDateRange = (start_date, end_date) => ({
  type: SET_SNAPSHOT_FILTER_DATE_RANGE,
  payload: {
    start_date: start_date,
    end_date: end_date
  },
});

export const setTempInventoryData = (list) => ({
  type: SET_TEMP_INVENTORY_DATA,
  payload: list,
});

export const setBulkSnapshotList = (bulk_snapshot_list) => ({
  type: SET_BULK_SNAPSHOT_LIST,
  payload: bulk_snapshot_list,
});

export const setGstr2bDate = (date) =>({
  type: SET_GSTR2B_DATE,
  payload: date
});

