import React from 'react'
import { Button, Modal } from 'react-bootstrap';


class Gstr2bDirtyFieldModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  render() {
    return (
      <Modal
        show={this.props.visible}
        backdrop="static"
        keyboard={false}
        centered
        className="popup-model error-popup"
      >
        <Modal.Body className="clearfix ">
          {this.props.onClose ?
            <>
              {/* <p className="blue-text text-center s-sbold mb-1 size-xxv">
                Changes are not saved
              </p>
              <p className="pmsg-text pmsg-gray-center">
              Do you still want to leave?
              </p> */}
            </>
            :
            <>
              <p className="pmsg-text pmsg-gray-center">
              This action will discard changes. Do you wish to proceed?
              </p>
            </>
          }

          {!this.props.onClose ?
            <div className="center_apply_btn_new">
              <Button variant="success apply_btn_new" onClick={() => this.props.onConfirm()}>No</Button>
              <Button variant="success apply_btn_new" onClick={() => this.props.onDontSave()}>Yes</Button>
              {/* <Button variant="outline-success reset_btn_new" onClick={() => this.props.onCancel()}>Cancel</Button> */}
            </div>
            :
            <div className="center_apply_btn_new">
              <Button variant="outline-success reset_btn_new" onClick={() => this.props.onCancel()}>No</Button>
              <Button variant="success apply_btn_new" onClick={() => this.props.onDontSave()}>Yes</Button>
            </div>
          }
        </Modal.Body>


        {/* <Modal.Body className="clearfix ">
            { this.props.onClose ? <div>
              <p className="popup-blue-text text-center">
                Changes are not saved
              </p>
              <p className="popup-gray-text text-center">
                Do you still want to leave ?
              </p>
            </div> : <div>
              <p className="popup-gray-text text-center">
                Do you want to save the changes ?
              </p>
            </div>}
        </Modal.Body>
        { !this.props.onClose ? 
          <Modal.Footer className='bottom-save-btn'>
            <Button onClick={() => this.props.onConfirm()} variant="" className="btn save-btn greenbtn model-save-btn">Yes</Button>
            <Button onClick={() => this.props.onDontSave()} variant="" className="btn save-btn greenbtn model-save-btn">No</Button>
            <Button onClick={() => this.props.onCancel()} variant="" className="btn model-save-btn save-btn graybtn">Cancel</Button>
          </Modal.Footer>  
          :
          <Modal.Footer className='bottom-save-btn'>
            <Button onClick={() => this.props.onCancel()} variant="" className="btn model-save-btn save-btn graybtn">No</Button>
          <Button onClick={() => this.props.onDontSave()} variant="" className="btn save-btn greenbtn model-save-btn">Yes</Button>
         
        </Modal.Footer>
        } */}


        {/* <Modal.Body className="clearfix row">
          <div className="mr-2 col-1">
            <span class="icon-exclamation-red-sign popicon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
          </div>
          { this.props.onClose ? <div className="col">
            <p className="ft-sz-30 s-sbold blu-color mb-1">Changes are not saved</p>
            <p className="ft-sz-18 s-reg font-colr">Do you still want to leave ?</p>
          </div> : <div className="col d-flex align-items-center">
            <p className="ft-sz-18 s-reg font-colr mb-0">Do you want to save the changes?</p>
          </div>}
        </Modal.Body> */}
        {/* { !this.props.onClose ? 
          <Modal.Footer>
            <Button onClick={() => this.props.onConfirm()} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">Yes</Button>
            <Button onClick={() => this.props.onDontSave()} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">No</Button>
            <Button onClick={() => this.props.onCancel()} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">Cancel</Button>
          </Modal.Footer>  
          :
          <Modal.Footer>
            <Button onClick={() => this.props.onCancel()} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold drk-gry-bg">No</Button>
          <Button onClick={() => this.props.onDontSave()} variant="" className="model-save-btn btn apply-btn ft-sz-22 white-color s-bold">Yes</Button>
         
        </Modal.Footer>
        } */}

      </Modal>
    )
  }
}

export default Gstr2bDirtyFieldModal;